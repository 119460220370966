import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import { getUserDetails } from "../../redux/slice/authSlice";
import LoginPopup from "../../components/blog/lib/LoginPopup";

function Verifyemail() {
  const { token } = useParams();
  const navigate = useNavigate();
  const API_URL = `${process.env.REACT_APP_API_URL}`;
  const [status, setStatus] = useState("initial"); // initial, verifying, success, error
  const [message, setMessage] = useState("");
  const { isAuthenticated, user } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  // Check initial state when component mounts
  useEffect(() => {
    if (user?.is_active) {
      setStatus("already-verified");
      setMessage("Your account is already verified!");
    }
  }, [user]);

  const verifyEmail = async () => {
    if (!isAuthenticated) {
      setStatus("error");
      setMessage("Please sign in to verify your email.");
      return;
    }

    if (user?.is_active) {
      setStatus("already-verified");
      setMessage("Your account is already verified!");
      return;
    }

    setStatus("verifying");
    setMessage("Verifying your email...");

    try {
      const response = await fetch(`${API_URL}/verify-email/${token}/`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });

      const data = await response.json();

      if (response.ok) {
        setStatus("success");
        setMessage(data.message || "Email verified successfully!");
        await dispatch(getUserDetails());
        setTimeout(() => {
          navigate("/user-account");
        }, 2000);
      } else {
        setStatus("error");
        setMessage(data.error || "Failed to verify email.");
      }
    } catch (error) {
      setStatus("error");
      setMessage(
        "An error occurred while verifying your email. Please try again later."
      );
    }
  };

  const renderContent = () => {
    switch (status) {
      case "already-verified":
        return (
          <div className="text-center">
            <div className="mb-4">
              <svg
                className="w-16 h-16 text-green-500 mx-auto"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M5 13l4 4L19 7"
                ></path>
              </svg>
            </div>
            <h5 className="text-2xl font-bold text-gray-900 mb-4">
              Account Already Verified
            </h5>
            <p className="text-gray-600 mb-4">{message}</p>
            <p className="text-sm text-gray-500">
              Redirecting to your account...
            </p>
          </div>
        );

      case "success":
        return (
          <div className="text-center">
            <div className="mb-4">
              <svg
                className="w-16 h-16 text-green-500 mx-auto"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M5 13l4 4L19 7"
                ></path>
              </svg>
            </div>
            <h5 className="text-2xl font-bold text-gray-900 mb-4">
              Email Verified!
            </h5>
            <p className="text-gray-600 mb-4">{message}</p>
            <p className="text-sm text-gray-500">
              Redirecting to your account...
            </p>
          </div>
        );

      case "error":
        return (
          <div className="text-center">
            <div className="mb-4">
              <svg
                className="w-16 h-16 text-red-500 mx-auto"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M6 18L18 6M6 6l12 12"
                ></path>
              </svg>
            </div>
            <h5 className="text-2xl font-bold text-gray-900 mb-4">
              Verification Failed
            </h5>
            <p className="text-red-600 mb-4">{message}</p>
            {!isAuthenticated && <LoginPopup />}
          </div>
        );

      default:
        return (
          <div className="text-center">
            <h5 className="text-2xl font-bold text-gray-900 mb-4">
              Verify Your Email
            </h5>
            <p className="text-gray-600 mb-4">
              {isAuthenticated
                ? "Thanks for signing up! Please verify your email address to continue."
                : "Please sign in to verify your email address."}
            </p>
            {isAuthenticated ? (
              <button
                onClick={verifyEmail}
                className="inline-flex items-center px-4 py-2 text-sm font-medium text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 transition-colors"
                disabled={status === "verifying"}
              >
                {status === "verifying" ? (
                  <>
                    <svg
                      className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <circle
                        className="opacity-25"
                        cx="12"
                        cy="12"
                        r="10"
                        stroke="currentColor"
                        strokeWidth="4"
                      ></circle>
                      <path
                        className="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                      ></path>
                    </svg>
                    Verifying...
                  </>
                ) : (
                  "Verify Email"
                )}
              </button>
            ) : (
              <div className="space-y-4">
                <p className="text-sm text-gray-500">
                  Sign in to verify your email address
                </p>
                <LoginPopup />
              </div>
            )}
            {message && <p className="mt-4 text-sm text-gray-600">{message}</p>}
          </div>
        );
    }
  };

  return (
    <div className="flex flex-col items-center  min-h-screen bg-gray-50 px-6 py-20">
      <div className="w-full max-w-md bg-white rounded-lg shadow-md p-8 space-y-4">
        {renderContent()}
      </div>
    </div>
  );
}

export default Verifyemail;
