import React, { useState } from "react";
import axios from "axios";
import { useSelector } from "react-redux";

const ResendVerificationEmail = () => {
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const user = useSelector((state) => state.auth.user);
  const API_URL = `${process.env.REACT_APP_API_URL}`;

  const handleResendEmail = async (e) => {
    setMessage("");
    setError("");
    setLoading(true);
    setShowSuccess(false);

    try {
      const response = await axios.post(`${API_URL}/resend-verification/`, {
        email: user.email,
      });

      setMessage(response.data.message);
      setShowSuccess(true);

      // Hide success message after 5 seconds
      setTimeout(() => {
        setShowSuccess(false);
      }, 600000);
    } catch (error) {
      if (error.response && error.response.status === 400) {
        setError(error.response.data.error);
      } else {
        setError(
          "Failed to resend verification email. Please try again later."
        );
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="flex flex-col items-center justify-center p-6 bg-white rounded-lg shadow-sm">
      <div className="text-center mb-4">
        <p className="text-gray-700 mb-4">
          Your email is not verified, please verify to see your accounts page.
        </p>

        {showSuccess && message && (
          <div className="mb-4 p-4 bg-green-100 text-green-700 rounded-md flex items-center">
            <svg
              className="w-5 h-5 mr-2"
              fill="currentColor"
              viewBox="0 0 20 20"
            >
              <path
                fillRule="evenodd"
                d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                clipRule="evenodd"
              />
            </svg>
            {message}
          </div>
        )}

        {error && (
          <div className="mb-4 p-4 bg-red-100 text-red-700 rounded-md">
            {error}
          </div>
        )}

        <button
          onClick={handleResendEmail}
          disabled={loading}
          className={`flex items-center justify-center px-6 py-2 rounded-md text-white transition-all duration-300 ease-in-out ${
            loading
              ? "bg-blue-400 cursor-not-allowed"
              : "bg-blue-600 hover:bg-blue-700"
          }`}
        >
          {loading ? (
            <>
              <div className="animate-spin rounded-full h-5 w-5 border-b-2 border-white mr-3"></div>
              Sending...
            </>
          ) : (
            <>
              <svg
                className="w-5 h-5 mr-2"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
                />
              </svg>
              Resend verification email
            </>
          )}
        </button>
      </div>
    </div>
  );
};

export default ResendVerificationEmail;
