import React from "react";
import { NavLink, useNavigate } from "react-router-dom";
import styles from "./navbar.module.css";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../redux/slice/authSlice";
import UserProfile from "../userProfile/UserProfile";

const Navbar = () => {
  const [showNavbar, setShowNavbar] = React.useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);

  const handleLogout = () => {
    dispatch(logout());
  };
  const handleClick = () => {
    navigate("/");
  };

  const handleShowNavbar = () => {
    setShowNavbar(!showNavbar);
  };

  return (
    <div className={styles.navWrapper}>
      <nav className={styles.navbar}>
        <div className={styles.container}>
          <div className={styles.logoContainer}>
            <div className={styles.menuIcon} onClick={handleShowNavbar}>
              <FontAwesomeIcon
                icon={faBars}
                style={{ width: " 18px", height: "18px" }}
              />
            </div>
            <div onClick={handleClick} className={styles.logo}>
              {/* <div className={styles.logoImg}> <img src="../bankLogos/wanijLogo.png" alt="website logo" /></div> */}
              <h3>Wanij</h3>
            </div>
          </div>
          {showNavbar ? (
            <div
              className={styles.transparentDiv}
              onClick={() => setShowNavbar(false)}
            ></div>
          ) : null}
          <div
            className={`${styles.navElements}  ${showNavbar && styles.active}`}
          >
            <ul onClick={showNavbar && styles.active ? handleShowNavbar : null}>
              <li>
                <NavLink
                  to="/"
                  className={({ isActive }) =>
                    isActive ? styles.ActiveNav : null
                  }
                >
                  Home
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/FDpage"
                  className={({ isActive }) =>
                    isActive ? styles.ActiveNav : null
                  }
                >
                  Fixed Deposits
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/savingsPage"
                  className={({ isActive }) =>
                    isActive ? styles.ActiveNav : null
                  }
                >
                  Savings Deposits
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/blog"
                  className={({ isActive }) =>
                    isActive ? styles.ActiveNav : null
                  }
                >
                  Blog
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/content"
                  className={({ isActive }) =>
                    isActive ? styles.ActiveNav : null
                  }
                >
                  Learning Centre
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/contactUs"
                  className={({ isActive }) =>
                    isActive ? styles.ActiveNav : null
                  }
                >
                  Contact Us
                </NavLink>
              </li>
              {auth.isAuthenticated ? (
                <li>
                  <button
                    onClick={handleLogout}
                    type="button"
                    class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2 text-center inline-flex items-center D:bg-blue-600 D:hover:bg-blue-700 D:focus:ring-blue-800"
                  >
                    Sign out
                    {/* <svg
                      class="rtl:rotate-180 w-3.5 h-3.5 ms-2"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 14 10"
                    >
                      <path
                        stroke="currentColor"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M1 5h12m0 0L9 1m4 4L9 9"
                      />
                    </svg> */}
                  </button>
                </li>
              ) : (
                <div className="flex">
                  <li>
                    <NavLink to={"/signin"}>
                      <button
                        type="button"
                        class="text-gray-900 mr-4 hover:text-white border border-gray-800 hover:bg-gray-900 focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2 text-center inline-flex items-center D:bg-blue-600 D:hover:bg-blue-700 D:focus:ring-blue-800"
                      >
                        Sign in
                        {/* <svg
                      class="rtl:rotate-180 w-3.5 h-3.5 ms-2"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 14 10"
                    >
                      <path
                        stroke="currentColor"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M1 5h12m0 0L9 1m4 4L9 9"
                      />
                    </svg> */}
                      </button>
                    </NavLink>
                    <NavLink to={"/signup"}>
                      <button
                        type="button"
                        class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2 text-center inline-flex items-center D:bg-blue-600 D:hover:bg-blue-700 D:focus:ring-blue-800"
                      >
                        Sign up
                        {/* <svg
                      class="rtl:rotate-180 w-3.5 h-3.5 ms-2"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 14 10"
                    >
                      <path
                        stroke="currentColor"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M1 5h12m0 0L9 1m4 4L9 9"
                      />
                    </svg> */}
                      </button>
                    </NavLink>
                  </li>
                  {/* <li></li> */}
                </div>
              )}
            </ul>
          </div>
          {auth.isAuthenticated ? <UserProfile /> : null}
        </div>
      </nav>
    </div>
  );
};

export default Navbar;
