import React, { useState } from "react";
import { Search, X } from "lucide-react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import axios from "axios";

const BankSelectionModal = () => {
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedBanks, setSelectedBanks] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const bankName = useSelector((state) => state.fdRateData.data1.top_fd);
  const email = useSelector((state) => state.auth.user.email);
  const navigate = useNavigate();
  const API_URL = "https://backend.wanij.com/";

  console.log("bankName", bankName);

  const filteredBanks = bankName.filter(
    (bank) =>
      bank.name?.toLowerCase().includes(searchQuery.toLowerCase()) ||
      bank.bref?.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const handleBankSelection = (bank) => {
    if (selectedBanks.includes(bank)) {
      setSelectedBanks(selectedBanks.filter((b) => b !== bank));
    } else if (selectedBanks.length < 5) {
      setSelectedBanks([...selectedBanks, bank]);
    }
  };

  const updatePreferences = async () => {
    try {
      setLoading(true);
      const selectedBankRefs = selectedBanks.map((bank) => bank.bref);

      const response = await axios.post(API_URL + "bank-preferences/", {
        banks: selectedBankRefs,
        email: email,
      });
      console.log("response", response);
      setLoading(false);
      navigate("/");
    } catch (err) {
      setError(err.response?.data?.error || "Failed to update preferences");
      setLoading(false);
      console.error("Error:", err);
    }
  };

  return (
    <div className="fixed inset-0 bg-white z-50 overflow-y-auto">
      <div className="max-w-4xl mx-auto px-4 py-16 mt-5">
        {/* Header */}
        <div className="text-center mb-12">
          <h1 className="text-4xl font-bold mb-4">Select Your Banks</h1>
          <p className="text-gray-600 text-xl">
            Choose up to 5 banks to receive notifications about the latest FD
            and SD rates
          </p>
        </div>

        {/* Search Bar */}
        <div className="relative mb-8 max-w-xl mx-auto">
          <Search className="absolute left-4 top-1/2 transform -translate-y-1/2 text-gray-400 w-5 h-5" />
          <input
            type="text"
            className="w-full pl-12 pr-4 py-3 rounded-full border border-gray-200 focus:outline-none focus:border-gray-400 text-lg"
            placeholder="Search banks..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
        </div>

        {/* Selected Banks Pills */}
        {/* {selectedBanks.length > 0 && (
          <div className="flex flex-wrap gap-2 mb-6 max-w-xl mx-auto">
            {selectedBanks.map((bank) => (
              <span
                key={bank.name}
                className="bg-black text-white px-4 py-2 rounded-full text-sm flex items-center gap-2"
              >
                {bank.name}
                <X
                  className="w-4 h-4 cursor-pointer hover:text-gray-300"
                  onClick={() => handleBankSelection(bank)}
                />
              </span>
            ))}
          </div>
        )} */}

        {/* Banks Grid */}
        <div className="grid grid-cols-2 md:grid-cols-4 gap-5 mb-24">
          {filteredBanks.map((bank) => (
            <div
              key={bank.name}
              onClick={() => handleBankSelection(bank)}
              className={`inline-flex items-center gap-1.5 px-4 py-1 rounded-full
        transition-all duration-200 text-sm font-medium ${
          selectedBanks.includes(bank)
            ? "bg-green-50 text-green-600 border border-green-600"
            : "bg-gray-50 text-gray-700 border border-gray-300 hover:bg-gray-100"
        }`}
            >
              <div className="flex items-center justify-between">
                <div class=" mr-3">
                  <img
                    class="w-8 h-8 rounded"
                    src={`../bankLogos/${bank.bcode}.png`}
                    alt="Rounded avatar"
                  />
                </div>
                <span className="text-lg font-small">{bank.mobile_name}</span>
                {selectedBanks.includes(bank) && (
                  <div className="flex items-center justify-space-between">
                    <svg
                      className="w-5 h-5"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                    >
                      <path
                        fillRule="evenodd"
                        d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </div>
                )}
              </div>
            </div>
          ))}
        </div>

        {/* Fixed Footer */}
        <div className="fixed bottom-0 left-0 right-0 bg-white border-t border-gray-200 p-3">
          <div className="max-w-3xl mx-auto flex items-center justify-between">
            <span className="text-lg text-gray-600">
              {selectedBanks.length} of 5 banks selected
            </span>
            <button
              onClick={updatePreferences}
              disabled={selectedBanks.length === 0 || loading}
              className={`px-8 py-3 rounded-full font-medium text-lg transition-all duration-200
                ${
                  selectedBanks.length > 0
                    ? "bg-black text-white hover:bg-gray-800"
                    : "bg-gray-100 text-gray-400 cursor-not-allowed"
                }`}
            >
              {loading ? "Saving..." : "Continue"}
            </button>
          </div>
        </div>

        {/* Error Message */}
        {error && (
          <div className="fixed top-4 right-4 bg-red-100 text-red-800 px-4 py-2 rounded-lg">
            {error}
          </div>
        )}
      </div>
    </div>
  );
};

export default BankSelectionModal;
