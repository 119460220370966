import Filters from "../Filters/Filters.jsx";
import MobileFilters from "../Filters/MobileFilters.jsx";
import styles from "./banklisting.module.css";
import { Link } from "react-router-dom";
import { useEffect, useState, useMemo, useRef } from "react";
import LoadingIndicator from "../../components/LoadingIndicator";
import Message from "../Message/Message.jsx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSortUp } from "@fortawesome/free-solid-svg-icons";
import { faSortDown } from "@fortawesome/free-solid-svg-icons";
import { faSort } from "@fortawesome/free-solid-svg-icons";
import { Tooltip } from "@nextui-org/tooltip";
import MessagePage from "../Message/MessagePage.jsx";
import { lastUpdateDate } from "../../utils/lastUpdateDate.js";
import { Typography } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import Chip from "@mui/material/Chip";
import ChevronDownIcon from "../../components/icons/ChevronDownIcon.jsx";
import Checkbox from "@mui/material/Checkbox";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import SearchBank from "../SearchBank/SearchBank.jsx";
import SearchBar from "../SearchBar/SearchBar.jsx";
import { Button } from "@nextui-org/react";
import {
  toggleFilter,
  resetFilters,
  setSelectedBanks,
  setSortedItems,
} from "../../redux/slice/savingsFullRatesSlice.js";
import { useDispatch, useSelector } from "react-redux";

function BankListing({ data }) {
  const bankData = useSelector((state) => state.savingsFullRate.bankData);
  const loader = useSelector((state) => state.savingsFullRate.isLoading);
  const selectedFilters = useSelector(
    (state) => state.savingsFullRate.selectedFilters
  );
  const [filteredItems, setFilteredItems] = useState(bankData);
  const [isAllChecked, setAllChecked] = useState(true);
  const [isMajorChecked, setMajorChecked] = useState(false);
  const [filterIsClicked, setFilterIsClicked] = useState(false);
  const [showMsgPage, setShowMsgPage] = useState(false);
  const Mbanks = bankData.filter((Mbank) => Mbank.major_bank);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 568);
  const [isTablet, setIsTablet] = useState(window.innerWidth <= 991);
  const [mobileSearch, setIsMobileSearch] = useState(window.innerWidth <= 768);

  // const [selectedBanks, setSelectedBanks] = useState([]);
  const selectedBanks = useSelector(
    (state) => state.savingsFullRate.selectedBanks
  );
  const sortedItems = useSelector((state) => state.savingsFullRate.sortedItems);
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;
  const isFirstRender = useRef(true);
  const dispatch = useDispatch();

  let filters = [
    {
      filterName: "Public",
      filterCount: 12,
    },
    {
      filterName: "Private",
      filterCount: 21,
    },
    {
      filterName: "Small Finance",
      filterCount: 11,
    },
    {
      filterName: "Foreign",
      filterCount: 6,
    },
    {
      filterName: "Post Office",
      filterCount: 1,
    },
  ];
  const columns = [
    {
      name: "Rank",
      accessor: "rank1",
      sortable: true,
      def: true,
      classColumn: "rank",
    },
    {
      name: "Bank Name",
      accessor: "name",
      sortable: true,
      def: false,
      classColumn: "bankName",
    },

    {
      name: "Effective Rate",
      accessor: "effective_rate",
      sortable: true,
      def: false,
      classColumn: "interestRate",
    },
    {
      name: "Bank Type",
      accessor: "type",
      sortable: true,
      def: false,
      classColumn: "bankType",
    },
    {
      name: "Last Updated",
      accessor: "effdate",
      sortable: true,
      default: false,
      classColumn: "interestPayout",
    },
    // {
    //   name: "Interest Payout",
    //   accessor: "interest_payout",
    //   sortable: true,
    //   default: false,
    //   classColumn: "interestPayout",
    // },
  ];

  const [sortField, setSortField] = useState("");
  const [order, setOrder] = useState("asc");
  const [rankOrder, setRankOrder] = useState(true);
  const [lodingMessage, setLodingMessage] = useState([]);

  // useEffect(() => {
  //   setShowMsgPage(true);
  // }, []);

  const handleSortingChange = (accessor) => {
    //
    const sortOrder =
      accessor === sortField && order === "asc" ? "desc" : "asc";
    setSortField(accessor);
    setOrder(sortOrder);
    setRankOrder(false);
    //

    handleSorting(accessor, sortOrder);
  };

  const handleSorting = (sortField, sortOrder) => {
    if (sortField) {
      const sorted = [...filteredItems].sort((a, b) => {
        if (a[sortField] === null) return 1;
        if (b[sortField] === null) return -1;
        if (a[sortField] === null && b[sortField] === null) return 0;

        if (sortField === "effdate") {
          const dateA = new Date(a[sortField]);
          const dateB = new Date(b[sortField]);
          return (dateA - dateB) * (sortOrder === "asc" ? 1 : -1);
        }

        if (a[sortField] === b[sortField]) {
          return (
            b.rank1.toString().localeCompare(a.rank1.toString(), "en", {
              numeric: true,
            }) * (sortOrder === "asc" ? 1 : -1)
          );
        }
        return (
          a[sortField].toString().localeCompare(b[sortField].toString(), "en", {
            numeric: true,
          }) * (sortOrder === "asc" ? 1 : -1)
        );
      });

      setFilteredItems(sorted);
      //
    }
  };

  // const handleRankSorting = () => {
  //   // Get the rank of each bank based on the index of the item in the filteredItems array
  //   filteredItems.map((item, index) => {
  //     setRankSorting(index + 1);
  //   });

  //   // Set the rank of each bank to its index + 1
  //   if (rankOrder === true) {
  //     rankSort.sort((a, b) => a - b);
  //   } else {
  //     rankSort.sort((a, b) => b - a);
  //   }
  //   setRankOrder(!rankOrder);
  // };
  // const handleFilterButtonClick = (selectedCategory) => {
  //   if (selectedFilters.includes(selectedCategory)) {
  //     let filters = selectedFilters.filter(
  //       (element) => element !== selectedCategory
  //     );
  //     setSelectedFilters((prev) => {
  //       //
  //       return filters;
  //     });
  //   } else {
  //     setSelectedFilters([...selectedFilters, selectedCategory]);
  //   }
  // };

  useEffect(() => {
    filterItems();
    setLodingMessage([
      isAllChecked ? "All Banks" : isMajorChecked ? "25 Major Banks" : null,
      ...selectedFilters,
    ]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    selectedFilters,
    isAllChecked,
    bankData,
    isMajorChecked,
    filterIsClicked,
  ]);

  //
  useEffect(() => {
    if (selectedFilters.length === 0 && !isAllChecked && !isMajorChecked) {
      setMajorChecked(true);
    }
  }, [bankData]);

  const allHandler = (event) => {
    setRankOrder(true);
    if (event.target.checked && event.target.id === "All") {
      setFilteredItems(
        bankData.map((item, index) => {
          return { ...item, rank1: index + 1 };
        })
      );
      dispatch(resetFilters());
      setAllChecked(true);
      setMajorChecked(false);
    } else if (event.target.checked && event.target.id === "Major") {
      setFilteredItems(
        Mbanks.map((item, index) => {
          return { ...item, rank1: index + 1 };
        })
      );
      setAllChecked(false);
      setMajorChecked(true);
      dispatch(resetFilters());
    }
  };
  const filterItems = () => {
    setRankOrder(true);
    if (selectedFilters.length > 0) {
      setAllChecked(false);
      setMajorChecked(false);
      let tempItems = selectedFilters.map((selectedCategory) => {
        let temp = bankData.filter((item) => item.type === selectedCategory);
        return temp;
      });
      const sortedData = tempItems
        .flat()
        .sort((a, b) => b.effective_rate - a.effective_rate);
      setFilteredItems(
        sortedData.map((item, index) => {
          return { ...item, rank1: index + 1 };
        })
      );
    } else {
      // setFilteredItems(bankData);
      if (isAllChecked) {
        setFilteredItems(
          bankData.map((item, index) => {
            return { ...item, rank1: index + 1 };
          })
        );
      } else {
        setFilteredItems(
          Mbanks.map((item, index) => {
            return { ...item, rank1: index + 1 };
          })
        );
      }
    }
    // const newState = filteredItems.map((obj, index) => {
    //   return { ...obj, rank1: index + 1 };
    // });

    // setFilteredItems(newState);
    //
  };

  const clearHandler = () => {
    setAllChecked(false);
    // // setMajorChecked(false);
    dispatch(resetFilters());
  };

  const majorSelect = () => {
    setMajorChecked(true);
  };

  const BanksCounts = {
    "All Banks": bankData.length,
    majorLength: Mbanks.length,
    Public: bankData.filter((item) => item.type === "Public").length,
    Private: bankData.filter((item) => item.type === "Private").length,
    "Small Finance": bankData.filter((item) => item.type === "Small Finance")
      .length,
    Foreign: bankData.filter((item) => item.type === "Foreign").length,
    "Post Office": bankData.filter((item) => item.type === "Post Office")
      .length,
  };

  const searchedBanks = useMemo(() => {
    if (selectedBanks.length === 0) return filteredItems;
    const banks = filteredItems.filter((bank) =>
      selectedBanks.some(
        (selected) => selected.name.toLowerCase() === bank.name.toLowerCase()
      )
    );
    return banks.map((item, index) => {
      return { ...item, rank1: index + 1 };
    });
  }, [selectedBanks, filteredItems]);

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }

    if (searchedBanks.length === 0) {
      dispatch(setSelectedBanks([]));
    }
  }, [searchedBanks.length]);

  const handleChange = (event, newValue) => {
    dispatch(setSelectedBanks(newValue));
  };

  const sortedData = [...filteredItems].sort((a, b) =>
    a.name.localeCompare(b.name)
  );
  useEffect(() => {
    dispatch(setSortedItems(sortedData));
  }, [filteredItems]);

  return (
    // Container div with a class from CSS module styles
    <>
      {filterIsClicked ? (
        <MobileFilters
          filters={filters}
          // majorHandler={majorHandler}
          allHandler={allHandler}
          isAllChecked={isAllChecked}
          isMajorChecked={isMajorChecked}
          selectedFilters={selectedFilters}
          clearHandler={clearHandler}
          filterIsClicked={filterIsClicked}
          setFilterIsClicked={setFilterIsClicked}
          majorLength={Mbanks.length}
          BanksCounts={BanksCounts}
        />
      ) : null}
      <div className={styles.bankListWrapper}>
        <div className={styles.mobileSearchContainer}>
          <div>{mobileSearch && <SearchBar />}</div>
        </div>
        <div className={styles.bankListContainer}>
          <Filters
            filters={filters}
            // majorHandler={majorHandler}
            allHandler={allHandler}
            isAllChecked={isAllChecked}
            isMajorChecked={isMajorChecked}
            selectedFilters={selectedFilters}
            clearHandler={clearHandler}
            majorLength={Mbanks.length}
            BanksCounts={BanksCounts}
            filteredItems={filteredItems}
            selectedBanks={selectedBanks}
            setSelectedBanks={setSelectedBanks}
            searchedBanks={searchedBanks}
            sortedItems={sortedItems}
          />

          <div className={styles.tableWraper}>
            {!mobileSearch ? (
              <div className={styles.searchContianer}>
                <SearchBank
                  selectedBanks={selectedBanks}
                  sortedItems={sortedItems}
                  setSelectedBanks={setSelectedBanks}
                  width={768}
                  minHeight={"2.7rem"}
                />

                <SearchBar />
              </div>
            ) : null}
            <div className={styles.tableHeaderElements}>
              <div className={styles.filterParentContainer}>
                <div className={styles.filterButtonContainer}>
                  <Button
                    onClick={() => setFilterIsClicked(!filterIsClicked)}
                    className={styles.filterButton}
                  >
                    <div className={styles.filterHeading}>
                      <img
                        width="14"
                        height="14"
                        src="../bankLogos/panel.png"
                        alt=""
                      />{" "}
                      <span style={{ paddingLeft: "2px" }}>Filters</span>
                      {!loader ? (
                        <span
                          style={{
                            color: "#007bff",
                            fontSize: "14px",
                            marginLeft: "2px",
                          }}
                        >
                          {selectedFilters.length > 0
                            ? `(${selectedFilters.length})`
                            : isAllChecked || isMajorChecked
                            ? "(1)"
                            : ""}
                        </span>
                      ) : null}
                    </div>
                  </Button>
                </div>
              </div>
              {mobileSearch ? (
                <SearchBank
                  selectedBanks={selectedBanks}
                  sortedItems={sortedItems}
                  setSelectedBanks={setSelectedBanks}
                  minHeight={"2.7rem"}
                />
              ) : null}
            </div>
            {/* : showMsgPage ? (
            <MessagePage />
          ) */}
            {loader ? (
              <LoadingIndicator lodingMessage={lodingMessage} />
            ) : !isAllChecked &&
              !isMajorChecked &&
              selectedFilters.length === 0 ? (
              <Message
                defaultSelect={majorSelect}
                heading={"Filters Needed"}
                content={
                  "Select Filters to see a List of Banks details or click below to view major banks"
                }
                buttonContent={"See Major Banks"}
              />
            ) : (
              <div className={styles.tableDiv}>
                <table className={styles.responsiveTable}>
                  <thead>
                    <tr>
                      {/* <th>rank33</th> */}
                      {columns.map(
                        (
                          { name, accessor, sortable, def, classColumn },
                          index
                        ) => {
                          const cl = sortable
                            ? sortField === accessor && order === "asc"
                              ? "Up"
                              : sortField === accessor && order === "desc"
                              ? "Down"
                              : "default"
                            : "";

                          return accessor === "rank1" && isMobile ? null : (
                            <th
                              key={accessor}
                              onClick={() => handleSortingChange(accessor)}
                              className={styles[classColumn]}
                            >
                              <div className={styles.sortDiv}>
                                <span className={styles.bankHeading}>
                                  {" "}
                                  {name === "Effective Rate" && isMobile
                                    ? "Rate"
                                    : name}
                                </span>

                                <span>
                                  {rankOrder === true && index === 0 ? (
                                    <FontAwesomeIcon
                                      icon={faSortUp}
                                      style={{ color: "007bff" }}
                                      className={styles.arrow}
                                    />
                                  ) : rankOrder === false && index === 0 ? (
                                    <FontAwesomeIcon
                                      icon={
                                        cl === "Up"
                                          ? faSortUp
                                          : cl === "Down"
                                          ? faSortDown
                                          : faSort
                                      }
                                      style={{
                                        color:
                                          cl !== "default"
                                            ? "#007bff"
                                            : "#4a5568",
                                      }}
                                      className={styles.arrow}
                                    />
                                  ) : null}

                                  {cl !== "default" &&
                                  rankOrder === false &&
                                  index !== 0 ? (
                                    <FontAwesomeIcon
                                      icon={cl === "Up" ? faSortUp : faSortDown}
                                      style={{ color: "#007bff" }}
                                      className={styles.arrow}
                                    />
                                  ) : index !== 0 ? (
                                    <FontAwesomeIcon
                                      icon={faSort}
                                      style={{ color: "#4a5568" }}
                                      className={styles.arrow}
                                    />
                                  ) : null}
                                </span>
                              </div>
                            </th>
                          );
                        }
                      )}
                      {/* <th></th> */}
                    </tr>
                  </thead>
                  <tbody>
                    {/* Mapping through bankData array to render rows */}

                    {searchedBanks.map((row, index) => (
                      <tr key={index}>
                        {/* Displaying bank data in respective columns */}
                        {!isMobile ? (
                          <td className={styles.rowRank}>{row.rank1}</td>
                        ) : null}
                        <td>
                          <div className={styles.card}>
                            <Link
                              to={row.bank_url}
                              target="_blank"
                              id="my-anchor-element"
                            >
                              <div className={styles.img}>
                                <img
                                  width="30x"
                                  height="30px"
                                  src={`../bankLogos/${row.bank_id}.png`}
                                  alt=""
                                  loading="lazy"
                                ></img>
                              </div>
                            </Link>

                            <div className={styles.textBox}>
                              {/* {row.type === "NBFC" ? (
                                <div className={styles.highlighterDiv}>
                                  <div
                                    className={styles.highlighter}
                                    aria-hidden="true"
                                  >
                                    {`${row.Agency || row.Agency1} ${
                                      row.Rating || row.Rating1
                                    }/${row.Outlook || row.Outlook1}`}
                                  </div>
                                </div>
                              ) : null} */}

                              <div className={styles.textContent}>
                                <Link
                                  to={row.bank_url}
                                  target="_blank"
                                  id="my-anchor-element"
                                >
                                  <Tooltip
                                    content="Go to Bank Website"
                                    showArrow={true}
                                  >
                                    <Typography
                                      // component="p"
                                      className={styles.h1}
                                      sx={{
                                        fontSize: "17px",
                                        color: "#202124;",
                                      }}
                                    >
                                      {isMobile ? row.mobile_name : row.name}
                                    </Typography>
                                    {/* <span className={styles.span}>12 min ago</span> */}
                                  </Tooltip>
                                </Link>
                              </div>

                              <Typography
                                // component="span"
                                variant="subtitle2"
                                sx={{
                                  mb: "5px",
                                  mt: "-2px",
                                  color: "#1a73e8",
                                  fontSize: "0.8rem",
                                }}
                              >
                                <Link
                                  target="_blank"
                                  to={`/savingsRateDetails/${row.name}`}
                                >
                                  Details
                                </Link>
                              </Typography>
                            </div>
                          </div>
                        </td>

                        <td className={styles.interestRateData}>
                          {Number(row.effective_rate).toFixed(2)}%
                        </td>
                        <td className={styles.bankType}>{row.type}</td>
                        {/* <td>
                        {row.interest_payout === "m"
                          ? "Monthly"
                          : row.interest_payout === "q"
                          ? "Quaterly"
                          : row.interest_payout}
                      </td> */}
                        <td>{row.effdate}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            )}
            {!loader &&
            (isAllChecked || isMajorChecked || selectedFilters.length > 0) ? (
              <div className={styles.features}>
                {/* <h4>Features and Benefits of {fdData[0].name} FD</h4> */}
                <div className={styles.featuresList}>
                  <ul>
                    <li aria-level="1">
                      <span>1. Data source: Official bank websites.</span>
                    </li>
                    {/* <li aria-level="1">
                      <span>
                        2. Rates are displayed for retail callable deposits{" "}
                        {"<"} Rs 3cr, unless otherwise specificed on the Full
                        Rate Details page of banks.
                      </span>
                    </li> */}
                    <li aria-level="1">
                      <span>2. Last Updated: {`${lastUpdateDate()}`}.</span>
                    </li>
                    <li aria-level="1">
                      <span>
                        3. Effective Rate calculation is performed in-house by
                        us. E&OE
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </>
  );
}

export default BankListing;
