import React, { useState, useRef, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { logout } from "../../redux/slice/authSlice";
import { Link } from "react-router-dom";
import { User, LogOut, Settings, CreditCard } from "lucide-react";

const UserProfile = () => {
  const user = useSelector((state) => state.auth.user);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);
  const buttonRef = useRef(null);
  const dispatch = useDispatch();

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target) &&
        !buttonRef.current.contains(event.target)
      ) {
        setDropdownOpen(false);
      }
    };

    const handleEscape = (event) => {
      if (event.key === "Escape") {
        setDropdownOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    document.addEventListener("keydown", handleEscape);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      document.removeEventListener("keydown", handleEscape);
    };
  }, []);

  const handleLogout = async () => {
    try {
      setDropdownOpen(false); // Close dropdown before logout
      await dispatch(logout());
    } catch (error) {
      console.error("Logout failed:", error);
    }
  };

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const DropdownItem = ({ children, onClick, icon: Icon, to }) => {
    const className =
      "flex items-center w-full px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 D:text-gray-200 D:hover:bg-gray-600 D:hover:text-white transition-colors duration-150";

    const handleClick = (e) => {
      if (onClick) {
        onClick(e);
      }
      setDropdownOpen(false); // Close dropdown after click
    };

    if (to) {
      return (
        <Link to={to} className={className} onClick={handleClick}>
          <Icon className="w-4 h-4 mr-2" />
          {children}
        </Link>
      );
    }

    return (
      <button onClick={handleClick} className={className}>
        <Icon className="w-4 h-4 mr-2" />
        {children}
      </button>
    );
  };

  return (
    <div className="relative">
      <button
        ref={buttonRef}
        aria-expanded={dropdownOpen}
        aria-haspopup="true"
        aria-label="User menu"
        className="flex items-center justify-center w-8 h-8 rounded-full bg-gray-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 transition-transform duration-200 hover:scale-105"
        onClick={toggleDropdown}
      >
        {user?.avatar ? (
          <img
            className="w-full h-full rounded-full object-cover"
            src={user.avatar}
            alt={`${user?.username || "User"}'s avatar`}
          />
        ) : (
          <User className="w-5 h-5 text-white" />
        )}
      </button>

      {dropdownOpen && (
        <div
          ref={dropdownRef}
          className="absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white D:bg-gray-700 ring-1 ring-black ring-opacity-5 divide-y divide-gray-100 D:divide-gray-600 transform origin-top-right transition-all duration-200 ease-out"
          role="menu"
          aria-orientation="vertical"
          aria-labelledby="user-menu"
        >
          <div className="px-4 py-3">
            <p className="text-sm font-medium text-gray-900 D:text-white">
              {user?.username || "User"}
            </p>
            <p className="text-sm text-gray-500 D:text-gray-300 truncate">
              {user?.email}
            </p>
          </div>

          <div className="py-1" role="none">
            <DropdownItem to="/user-account" icon={CreditCard}>
              Account
            </DropdownItem>
            <DropdownItem to="/settings" icon={Settings}>
              Settings
            </DropdownItem>
          </div>

          <div className="py-1" role="none">
            <DropdownItem onClick={handleLogout} icon={LogOut}>
              Sign out
            </DropdownItem>
          </div>
        </div>
      )}
    </div>
  );
};

export default UserProfile;
