import Filters from "../fdFilters/Filter.jsx";
import MobileFilters from "../fdFilters/MobileFilters.jsx";
import styles from "./fdTable.module.css";
import { Link } from "react-router-dom";
import { useEffect, useState, useMemo, useRef } from "react";
import LoadingIndicator from "../../components/LoadingIndicator";
import Message from "../../SavingsPage/Message/Message.jsx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSortUp } from "@fortawesome/free-solid-svg-icons";
import { faSortDown } from "@fortawesome/free-solid-svg-icons";
import { faSort } from "@fortawesome/free-solid-svg-icons";
import { Tooltip } from "@nextui-org/tooltip";
import { getYearsFromDays } from "../../utils/getDaysFromYearAndMonths.js";
import { getDaysFromYearAndMonths } from "../../utils/getDaysFromYearAndMonths.js";
import { lastUpdateDate } from "../../utils/lastUpdateDate.js";
import { findBestFdRate, seniorAndSuperSeniorCount } from "../../utils/data.js"; // fdData 2
import { useSelector, useDispatch } from "react-redux";
import { Typography } from "@mui/material";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import SearchBank from "../../SavingsPage/SearchBank/SearchBank.jsx";

import {
  toggleFilterFD,
  resetFiltersFD,
  setSliderRange,
  setSliderYear,
  setSliderMonth,
  setSelectedBanks,
} from "../../redux/slice/fdRateDataSlice.js";

import {
  Popover,
  PopoverTrigger,
  PopoverContent,
  Button,
  DropdownTrigger,
  Dropdown,
  DropdownMenu,
  DropdownItem,
} from "@nextui-org/react";

function FdTable() {
  const fdData2 = useSelector((state) => state.fdRateData.data1);
  const fdData = useSelector((state) => state.fdRateData.data2);
  console.log("fdData2", fdData);
  console.log("fdData1", fdData2);
  const loader = useSelector((state) => state.fdRateData.isLoading);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 568);
  const [isTablet, setIsTablet] = useState(window.innerWidth <= 1230);
  // const [sliderRange, setSliderRange] = useState(0);
  // const [month, setMonth] = useState(0);
  // const [year, setYear] = useState(0);
  const sliderRange = useSelector(
    (state) => state.fdRateData.sliderState.sliderRange
  );
  const month = useSelector((state) => state.fdRateData.sliderState.month);
  const year = useSelector((state) => state.fdRateData.sliderState.year);
  // const [selectedFilters, setSelectedFilters] = useState([]);
  const selectedFilters = useSelector(
    (state) => state.fdRateData.selectedFilters
  );
  // const [selectedTenor, setTenor] = useState("All terms");
  const selectedTenor = useSelector((state) => state.fdRateData.selectedTenor);
  const [tenorData, setTenorData] = useState([]);
  const [filteredItems, setFilteredItems] = useState(fdData2.top_fd);

  const [isAllChecked, setAllChecked] = useState(true);
  const [isMajorChecked, setMajorChecked] = useState(false);
  const [filterIsClicked, setFilterIsClicked] = useState(false);
  const [sliderData, setSliderData] = useState([]);
  const [showSeniorCitizen, setShowSeniorCitizen] = useState(false);
  const [showSupSeniorCitizen, setShowSupSeniorCitizen] = useState(false);
  const [showRegularRate, setRegularRate] = useState(true);
  const Mbanks = fdData2.top_fd.filter((item, index) => item.major_flag);
  // const [selectedBanks, setSelectedBanks] = useState([]);
  const selectedBanks = useSelector((state) => state.fdRateData.selectedBanks);
  const isFirstRender = useRef(true);
  const INITIAL_VISIBLE_COLUMNS = ["Rank, Bank Name, Bank Type"];
  const [visibleColumns, setVisibleColumns] = useState(new Set(["0"]));

  let filters = [
    {
      filterName: "Public",
      filterCount: 12,
    },
    {
      filterName: "Private",
      filterCount: 21,
    },
    {
      filterName: "Small Finance",
      filterCount: 11,
    },
    {
      filterName: "Foreign",
      filterCount: 6,
    },
    {
      filterName: "NBFC",
      filterCount: 7,
    },
    {
      filterName: "Post Office",
      filterCount: 0,
    },
  ];
  const columns = [
    {
      name: "Rank",
      accessor: "rank1",
      sortable: true,
      def: true,
      classColumn: "rank",
    },
    {
      name: "Bank Name",
      accessor: "name",
      sortable: true,
      def: false,
      classColumn: "bankName",
    },

    {
      name: "Base Rate",
      accessor: "fdrate",
      sortable: true,
      def: false,
      classColumn: "interestRate",
    },
    {
      name: "Senior Citizen",
      accessor: "addsenior",
      sortable: true,
      def: false,
      classColumn: "interestRate1",
    },
    {
      name: "Super Senior",
      accessor: "addsupsenior",
      sortable: true,
      def: false,
      classColumn: "interestRate2",
    },
    {
      name: "Tenor",
      accessor: "tenorstartday",
      sortable: true,
      default: false,
      classColumn: "tenor",
    },
    {
      name: "Bank Type",
      accessor: "type",
      sortable: true,
      def: false,
      classColumn: "bankType",
    },
    {
      name: "Last Updated",
      accessor: "effdate",
      sortable: true,
      def: false,
      classColumn: "bankType",
    },
  ];
  // const columnsToVisible = [
  //   "Regular Rates",
  //   "Senior Citizens(60 yr+)",
  //   "Super Sr. Citizens(80 yr+)",
  // ];

  const [sortField, setSortField] = useState("");
  const [order, setOrder] = useState("asc");
  const [rankOrder, setRankOrder] = useState(true);
  const [lodingMessage, setLodingMessage] = useState([]);
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;

  const dispatch = useDispatch();

  useEffect(() => {
    filterItems();
    setLodingMessage([
      isAllChecked ? "All Banks" : isMajorChecked ? "25 Major Banks" : null,
      ...selectedFilters,
    ]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    selectedFilters.length,
    isAllChecked,
    isMajorChecked,
    tenorData,
    selectedTenor,
    sliderData,
  ]);

  useEffect(() => {
    //
    handleSliderData();
    filterItems();
    // setFilteredItems(sliderData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [year, month, selectedTenor]);

  useEffect(() => {
    if (selectedTenor !== "" && selectedTenor !== "All terms") {
      //

      setFilteredItems(tenorData);
      handleTopFdRatesData();
      filterItems();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTenor, isAllChecked, isMajorChecked]); // see this

  const handleSortingChange = (accessor) => {
    //
    const sortOrder =
      accessor === sortField && order === "asc" ? "desc" : "asc";
    setSortField(accessor);
    setOrder(sortOrder);
    setRankOrder(false);
    //

    handleSorting(accessor, sortOrder);
  };

  const handleSorting = async (sortField, sortOrder) => {
    //

    if (sortField) {
      const sorted = [...filteredItems].sort((a, b) => {
        // Handle null values
        if (a[sortField] === null) return sortOrder === "asc" ? 1 : -1;
        if (b[sortField] === null) return sortOrder === "asc" ? -1 : 1;

        if (sortField === "effdate") {
          const dateA = new Date(a[sortField]);
          const dateB = new Date(b[sortField]);
          return (dateA - dateB) * (sortOrder === "asc" ? 1 : -1);
        }

        // Check if the values are numeric
        const isNumericA =
          !isNaN(parseFloat(a[sortField])) && isFinite(a[sortField]);
        const isNumericB =
          !isNaN(parseFloat(b[sortField])) && isFinite(b[sortField]);

        // If both values are numeric, perform numeric comparison
        if (isNumericA && isNumericB) {
          const numA = parseFloat(a[sortField]);
          const numB = parseFloat(b[sortField]);
          return (numA - numB) * (sortOrder === "asc" ? 1 : -1);
        }

        // If only one value is numeric, place it after the non-numeric value
        if (isNumericA && !isNumericB) {
          return sortOrder === "asc" ? -1 : 1;
        }
        if (!isNumericA && isNumericB) {
          return sortOrder === "asc" ? 1 : -1;
        }

        // If both values are non-numeric, perform string comparison
        return (
          a[sortField].localeCompare(b[sortField]) *
          (sortOrder === "asc" ? 1 : -1)
        );
      });

      setFilteredItems(sorted);

      //
    }
  };

  const handleFilterButtonClick = (selectedCategory) => {
    dispatch(toggleFilterFD(selectedCategory));
  };

  const allHandler = (event) => {
    setRankOrder(true);
    if (event.target.checked && event.target.id === "All") {
      // setFilteredItems(
      //   fdData2.top_fd.map((item, index) => {
      //     return { ...item, rank1: index + 1 };
      //   })
      // );
      dispatch(resetFiltersFD());
      setAllChecked(true);
      setMajorChecked(false);
    } else if (event.target.checked && event.target.id === "Major") {
      // setFilteredItems(
      //   Mbanks.map((item, index) => {
      //     return { ...item, rank1: index + 1 };
      //   })
      // );
      setAllChecked(false);
      setMajorChecked(true);
      dispatch(resetFiltersFD());
    }
  };
  const filterItems = () => {
    setRankOrder(true);
    if (selectedFilters.length > 0 && selectedTenor === "All terms") {
      setAllChecked(true);
      setMajorChecked(false);
      let tempItems = selectedFilters.map((selectedCategory) => {
        let temp = fdData2.top_fd.filter(
          (item) => item.type === selectedCategory
        );
        return temp;
      });
      const sortedData = tempItems.flat().sort((a, b) => b.fdrate - a.fdrate);
      setFilteredItems(
        sortedData.map((item, index) => {
          return { ...item, rank1: index + 1 };
        })
      );
    } else if (
      selectedFilters.length > 0 &&
      selectedTenor !== "All terms" &&
      month === 0 &&
      year === 0
    ) {
      setAllChecked(true);
      setMajorChecked(false);

      let tempItems = selectedFilters.map((selectedCategory) => {
        let temp = tenorData.filter((item) => item.type === selectedCategory);
        return temp;
      });
      const sortedData = tempItems.flat().sort((a, b) => b.fdrate - a.fdrate);
      setFilteredItems(
        sortedData.map((item, index) => {
          return { ...item, rank1: index + 1 };
        })
      );
    } else if (selectedFilters.length > 0 && sliderData.length > 0) {
      setAllChecked(true);
      setMajorChecked(false);
      let tempItems = selectedFilters.map((selectedCategory) => {
        let temp = sliderData.filter((item) => item.type === selectedCategory);
        return temp;
      });
      const sortedData = tempItems.flat().sort((a, b) => b.fdrate - a.fdrate);
      setFilteredItems(
        sortedData.map((item, index) => {
          return { ...item, rank1: index + 1 };
        })
      );
    } else {
      //

      if (
        isAllChecked &&
        (selectedTenor === "All terms" || selectedTenor === "") &&
        month === 0 &&
        year === 0
      ) {
        setFilteredItems(
          fdData2.top_fd.map((item, index) => {
            return { ...item, rank1: index + 1 };
          })
        );
      } else if (selectedTenor !== "All terms" && month === 0 && year === 0) {
        const sortedData = tenorData.sort((a, b) => b.fdrate - a.fdrate);
        setFilteredItems(
          sortedData.map((item, index) => {
            return { ...item, rank1: index + 1 };
          })
        );
      } else if (isMajorChecked && selectedTenor === "All terms") {
        setFilteredItems(
          Mbanks.map((item, index) => {
            return { ...item, rank1: index + 1 };
          })
        );
      } else if (sliderData.length > 0) {
        // const sortedData = sliderData.sort((a, b) => b.fdrate - a.fdrate);
        setFilteredItems(sliderData);
      } else if (isMajorChecked) {
        setFilteredItems(
          Mbanks.map((item, index) => {
            return { ...item, rank1: index + 1 };
          })
        );
      }
    }
  };

  const clearHandler = () => {
    setAllChecked(true);
    setMajorChecked(false);
    dispatch(resetFiltersFD());
  };

  const majorSelect = () => {
    setMajorChecked(true);
  };
  const allSelect = () => {
    setAllChecked(true);
    dispatch(resetFiltersFD());
  };

  const handleTopFdRatesData = () => {
    //
    // setMonth(0);
    // setYear(0);
    if (selectedTenor === "0 - 1y") {
      // setTenor("6m");
      const data = findBestFdRate(0, 12, isAllChecked, fdData2);
      setTenorData(data);
    } else if (selectedTenor === "1 - 2y") {
      // setTenor("1-2y");
      const data = findBestFdRate(12, 24, isAllChecked, fdData2);
      setTenorData(data);
    } else if (selectedTenor === "2 - 3y") {
      // setTenor("2-3y");

      const data = findBestFdRate(24, 36, isAllChecked, fdData2);
      setTenorData(data);
    } else if (selectedTenor === "All terms") {
      // setTenor("allTerm");

      const data = fdData2.top_fd;
      setTenorData(data);
    } else if (selectedTenor === "3 - 5y") {
      // setTenor("3-5y");

      const data = findBestFdRate(36, 60, isAllChecked, fdData2);
      setTenorData(data);
    } else if (selectedTenor === "Above 5y") {
      // setTenor("5+y");

      const data = findBestFdRate(60, 120, isAllChecked, fdData2);
      setTenorData(data);
    }
  };

  const handleSliderData = async (e) => {
    // setTenor("");
    // setTenorData([])
    if (year > 0 || month > 0) {
      setAllChecked(true);
      setMajorChecked(false);
    }
    //

    const userTenor = getDaysFromYearAndMonths(
      parseInt(month) + parseInt(year) * 12
    );
    //
    //

    const data = fdData.filter((item) => {
      return item.tenorstartday <= userTenor && item.tenorendday >= userTenor;
    });
    //

    const sortedData = data.sort((a, b) => b.fdrate - a.fdrate);
    setSliderData(
      sortedData.map((item, index) => {
        return { ...item, rank1: index + 1 };
      })
    );
  };

  const BanksCounts = {
    "All Banks": fdData2.top_fd.length,
    majorLength: Mbanks.length,
    Public: fdData2.top_fd.filter((fd) => fd.type === "Public").length,
    Private: fdData2.top_fd.filter((fd) => fd.type === "Private").length,
    "Small Finance": fdData2.top_fd.filter((fd) => fd.type === "Small Finance")
      .length,
    Foreign: fdData2.top_fd.filter((fd) => fd.type === "Foreign").length,
    NBFC: fdData2.top_fd.filter((fd) => fd.type === "NBFC").length,
    "Post Office": fdData2.top_fd.filter((fd) => fd.type === "Post Office")
      .length,
  };

  function removeSpaces(input) {
    // Define a regular expression to match numbers followed by a space and then letters (units)
    const regex = /(\d)\s+(yr|mo|d)/g;

    // Replace the matched patterns by removing the space between the number and the unit
    return input.replace(regex, "$1$2");
  }

  const searchedBanks = useMemo(() => {
    if (selectedBanks.length === 0) return filteredItems;

    const banks = filteredItems.filter((bank) =>
      selectedBanks.some(
        (selected) =>
          selected.name.toLowerCase() === bank.name.toLowerCase() ||
          selected.bref.toLowerCase() === bank.bref.toLowerCase()
      )
    );

    return banks.map((item, index) => {
      return { ...item, rank1: index + 1 };
    });
  }, [selectedBanks, filteredItems]);

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }

    // Dispatch the action only if `searchedBanks.length` is 0 after the first render
    if (searchedBanks.length === 0) {
      dispatch(setSelectedBanks([]));
    }
  }, [searchedBanks.length]);

  // useEffect(() => {
  //   handleChangeColumnSelect();
  // }, [visibleColumns]);
  //
  //
  const handleChange = (event, newValue) => {
    dispatch(setSelectedBanks(newValue));
  };

  const sortedItems = [...filteredItems].sort((a, b) =>
    a.name.localeCompare(b.name)
  );

  const handleChangeColumnSelect = () => {
    if (visibleColumns[0] === "0") setRegularRate(true);
    else setRegularRate(false);

    if (visibleColumns[1] === "1") setShowSeniorCitizen(true);
    else setShowSeniorCitizen(false);

    if (visibleColumns[2] === "2") setShowSupSeniorCitizen(true);
    else setShowSupSeniorCitizen(false);
  };

  return (
    <>
      <div className={styles.filterParentContainer}>
        <div className={styles.filterButtonContainer}>
          <Button
            onClick={() => setFilterIsClicked(!filterIsClicked)}
            className={styles.filterButton}
          >
            <div className={styles.filterHeading}>
              <img width="14" height="14" src="../bankLogos/panel.png" alt="" />{" "}
              <span style={{ paddingLeft: "2px" }}>Filters</span>
              {!loader ? (
                <span
                  style={{
                    color: "#007bff",
                    fontSize: "14px",
                    marginLeft: "2px",
                  }}
                >
                  {selectedFilters.length > 0
                    ? `(${selectedFilters.length})`
                    : isAllChecked || isMajorChecked
                    ? "(1)"
                    : ""}
                </span>
              ) : null}
            </div>
          </Button>
          {isTablet ? (
            <div className={`${styles.bankSearch} mb-3 md:mb-7`}>
              <SearchBank
                selectedBanks={selectedBanks}
                sortedItems={sortedItems}
                setSelectedBanks={setSelectedBanks}
                minHeight={"3.2rem"}
              />
            </div>
          ) : null}
        </div>
      </div>
      {filterIsClicked ? (
        <MobileFilters
          filters={filters}
          handleFilterButtonClick={handleFilterButtonClick}
          // majorHandler={majorHandler}
          allHandler={allHandler}
          isAllChecked={isAllChecked}
          isMajorChecked={isMajorChecked}
          selectedFilters={selectedFilters}
          clearHandler={clearHandler}
          majorLength={Mbanks.length}
          // findBestFdRate={handleTopFdRatesData}
          BanksCounts={BanksCounts}
          sliderRange={sliderRange}
          setSliderRange={setSliderRange}
          month={month}
          year={year}
          setYear={setSliderYear}
          setMonth={setSliderMonth}
          selectedTenor={selectedTenor}
          setTenorData={setTenorData}
          filterIsClicked={filterIsClicked}
          setFilterIsClicked={setFilterIsClicked}
        />
      ) : null}
      <div className={styles.bankListWrapper2}>
        {/* <div className={styles.emptyDivContainer}><div className={styles.emptyDiv}></div> */}

        {/* </div>  */}

        <div className={styles.bankListContainer2}>
          <div className={styles.filterContainer}>
            <Filters
              filters={filters}
              handleFilterButtonClick={handleFilterButtonClick}
              // majorHandler={majorHandler}
              allHandler={allHandler}
              isAllChecked={isAllChecked}
              isMajorChecked={isMajorChecked}
              selectedFilters={selectedFilters}
              clearHandler={clearHandler}
              majorLength={Mbanks.length}
              // findBestFdRate={handleTopFdRatesData}
              BanksCounts={BanksCounts}
              sliderRange={sliderRange}
              setSliderRange={setSliderRange}
              month={month}
              year={year}
              setYear={setSliderYear}
              setMonth={setSliderMonth}
              selectedTenor={selectedTenor}
              setTenorData={setTenorData}
            />
          </div>
          <div className={styles.tableWraper2}>
            <div className={styles.searchWrapper}>
              <div className={styles.searchContainer}>
                {!isTablet ? (
                  <div className={`${styles.bankSearch} w-full`}>
                    <SearchBank
                      selectedBanks={selectedBanks}
                      sortedItems={sortedItems}
                      setSelectedBanks={setSelectedBanks}
                      minHeight={"2.5rem"}
                    />
                  </div>
                ) : null}
                {/* <div className={styles.dropdown}>
                  <Dropdown>
                    <DropdownTrigger className=" sm:flex mt-2">
                      <Button
                        endContent={<ChevronDownIcon className="text-small" />}
                        variant="solid"
                        // size={isMobile ? "sm" : "default"}
                      >
                        Columns
                      </Button>
                    </DropdownTrigger>
                    <DropdownMenu
                      disallowEmptySelection
                      aria-label="Table Columns"
                      closeOnSelect={false}
                      selectedKeys={visibleColumns}
                      selectionMode="multiple"
                      onSelectionChange={setVisibleColumns}
                    >
                      {columnsToVisible.map((column, index) => (
                        <DropdownItem key={index} className="capitalize">
                          {column}
                        </DropdownItem>
                      ))}
                    </DropdownMenu>
                  </Dropdown>
                </div> */}
                <div className={`w-full ${styles.showColumnCheckbox}`}>
                  <span
                    class={`${
                      showRegularRate
                        ? "bg-blue-100 text-blue-800"
                        : "bg-gray-100"
                    }  text-xs font-medium me-2 pr-1.5 py-0.5 rounded dar111:bg-blue-900 dar111:text-blue-300`}
                  >
                    {" "}
                    <div className={styles.checkboxWrapper}>
                      <input
                        className={styles.showColumnInput}
                        type="checkbox"
                        name="regularRate"
                        // defaultChecked="true"
                        value="Germany"
                        id="regularRate"
                        checked={showRegularRate}
                        onChange={() => setRegularRate(!showRegularRate)}
                      />
                      <label htmlFor="regularRate">
                        <Typography
                          variant="subtitle1"
                          className={styles.seniorFlagCheck}
                          sx={{ fontWeight: "400" }}
                        >
                          {isMobile ? "Regular" : "Regular rates"}
                        </Typography>
                      </label>
                    </div>
                  </span>

                  <span
                    class={`${
                      showSeniorCitizen
                        ? "bg-blue-100 text-blue-800"
                        : "bg-gray-100"
                    } text-gray-800 text-xs font-medium me-2 pr-1.5 py-0.5 rounded dar111:bg-blue-900 dar111:text-blue-300`}
                  >
                    {" "}
                    <div className={styles.checkboxWrapper}>
                      <input
                        className={styles.showColumnInput}
                        type="checkbox"
                        name="seniorCitizen"
                        // defaultChecked="true"
                        value="Germany"
                        id="seniorCitizen"
                        checked={showSeniorCitizen}
                        onChange={() =>
                          setShowSeniorCitizen(!showSeniorCitizen)
                        }
                      />
                      <label htmlFor="seniorCitizen">
                        <Typography
                          variant="subtitle1"
                          className={styles.seniorFlagCheck}
                        >
                          {isMobile ? "Senior Citiz." : "Senior Citiz."}
                          <small className={styles.seniorYear}>(60 yr+)</small>
                        </Typography>
                      </label>

                      {!isMobile ? (
                        <Tooltip
                          // className={styles.infoTooltip}
                          trigger="click"
                          showArrow={true}
                          content={`${
                            seniorAndSuperSeniorCount(fdData2).seniorCount
                          } banks have special rates for Senior Citizens`}
                        >
                          <svg
                            width="15"
                            height="15"
                            viewBox="0 0 24 24"
                            focusable="false"
                            className={styles.information}
                          >
                            <path d="M11 17h2v-6h-2v6zm1-15C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8zM11 9h2V7h-2v2z"></path>
                          </svg>
                        </Tooltip>
                      ) : (
                        <Popover showArrow={true}>
                          <PopoverTrigger>
                            <svg
                              width="15"
                              height="15"
                              viewBox="0 0 24 24"
                              focusable="false"
                              className={styles.information}
                            >
                              <path d="M11 17h2v-6h-2v6zm1-15C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8zM11 9h2V7h-2v2z"></path>
                            </svg>
                          </PopoverTrigger>
                          <PopoverContent>
                            <div className="text-tiny">
                              {" "}
                              {`${
                                seniorAndSuperSeniorCount(fdData2).seniorCount
                              } banks have special rates for Senior Citizens`}
                            </div>
                          </PopoverContent>
                        </Popover>
                      )}
                    </div>
                  </span>
                  <span
                    class={`${
                      showSupSeniorCitizen
                        ? "bg-blue-100 text-blue-800"
                        : "bg-gray-100"
                    } text-gray-800 text-xs font-medium me-2 pr-1.5 py-0.5 rounded dar111:bg-blue-900 dar111:text-blue-300`}
                  >
                    {" "}
                    <div className={styles.checkboxWrapper}>
                      <input
                        className={styles.showColumnInput}
                        type="checkbox"
                        name="superSeniorCitizen"
                        // defaultChecked="true"
                        value="Germany"
                        id="superSeniorCitizen"
                        // checked
                        onChange={() =>
                          setShowSupSeniorCitizen(!showSupSeniorCitizen)
                        }
                      />
                      <label htmlFor="superSeniorCitizen">
                        <Typography
                          variant="subtitle1"
                          className={styles.seniorFlagCheck}
                          sx={{ fontWeight: "400" }}
                        >
                          {" "}
                          {isMobile ? "Super Sr." : "Super Senior citiz."}
                          <small className={styles.seniorYear}>(80 yr+)</small>
                        </Typography>
                      </label>

                      {!isMobile ? (
                        <Tooltip
                          showArrow={true}
                          content={`${
                            seniorAndSuperSeniorCount(fdData2).supSeniorCount
                          } banks have special rates for Super Senior Citizens`}
                        >
                          <svg
                            width="15"
                            height="15"
                            viewBox="0 0 24 24"
                            focusable="false"
                            className={styles.information}
                          >
                            <path d="M11 17h2v-6h-2v6zm1-15C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8zM11 9h2V7h-2v2z"></path>
                          </svg>
                        </Tooltip>
                      ) : (
                        <Popover showArrow={true}>
                          <PopoverTrigger>
                            <svg
                              width="15"
                              height="15"
                              viewBox="0 0 24 24"
                              focusable="false"
                              className={styles.information}
                            >
                              <path d="M11 17h2v-6h-2v6zm1-15C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8zM11 9h2V7h-2v2z"></path>
                            </svg>
                          </PopoverTrigger>

                          <PopoverContent>
                            <div className="text-tiny">
                              {" "}
                              {`${
                                seniorAndSuperSeniorCount(fdData2)
                                  .supSeniorCount
                              } banks have special rates for Super Senior Citizens`}
                            </div>
                          </PopoverContent>
                        </Popover>
                      )}
                    </div>
                  </span>
                </div>
              </div>
            </div>
            {/* : showMsgPage ? (
            <MessagePage />
          ) */}
            {loader ? (
              <LoadingIndicator lodingMessage={lodingMessage} />
            ) : !isAllChecked &&
              !isMajorChecked &&
              selectedFilters.length === 0 ? (
              <Message
                defaultSelect={majorSelect}
                heading={"Filters Needed"}
                content={
                  "Select Filters to see a List of Banks details or click below to view major banks"
                }
                buttonContent={"See Major Banks"}
              />
            ) : filteredItems.length === 0 ? (
              <Message
                defaultSelect={allSelect}
                heading={"Data not found"}
                content={
                  "No data available for the chosen filters. Click to reset."
                }
                buttonContent={"Reset Filters"}
              />
            ) : (
              <div className={styles.tableBorder}>
                <table className={styles.responsiveTable2}>
                  <thead>
                    <tr>
                      {/* <th>rank33</th> */}
                      {columns.map(
                        (
                          { name, accessor, sortable, def, classColumn },
                          index
                        ) => {
                          const cl = sortable
                            ? sortField === accessor && order === "asc"
                              ? "Up"
                              : sortField === accessor && order === "desc"
                              ? "Down"
                              : "default"
                            : "";
                          //
                          return (
                            <>
                              {(accessor === "addsenior" &&
                                showSeniorCitizen === false) ||
                              (accessor === "fdrate" &&
                                showRegularRate === false) ||
                              (accessor === "addsupsenior" &&
                                showSupSeniorCitizen === false) ||
                              (accessor === "rank1" && isMobile) ? null : (
                                <th
                                  key={index}
                                  onClick={() => handleSortingChange(accessor)}
                                >
                                  <div className={styles[classColumn]}>
                                    <span>
                                      {" "}
                                      {name === "Base Rate" && isMobile
                                        ? "Rate"
                                        : name}
                                    </span>

                                    <span>
                                      {rankOrder === true && index === 0 ? (
                                        <FontAwesomeIcon
                                          icon={faSortUp}
                                          style={{ color: "007bff" }}
                                          className={styles.arrow}
                                        />
                                      ) : rankOrder === false && index === 0 ? (
                                        <FontAwesomeIcon
                                          icon={
                                            cl === "Up"
                                              ? faSortUp
                                              : cl === "Down"
                                              ? faSortDown
                                              : faSort
                                          }
                                          style={{
                                            color:
                                              cl !== "default"
                                                ? "#007bff"
                                                : "#4a5568",
                                          }}
                                          className={styles.arrow}
                                        />
                                      ) : null}

                                      {cl !== "default" &&
                                      rankOrder === false &&
                                      index !== 0 ? (
                                        <FontAwesomeIcon
                                          icon={
                                            cl === "Up" ? faSortUp : faSortDown
                                          }
                                          style={{ color: "#007bff" }}
                                          className={styles.arrow}
                                        />
                                      ) : index !== 0 ? (
                                        <FontAwesomeIcon
                                          icon={faSort}
                                          style={{ color: "#4a5568" }}
                                          className={styles.arrow}
                                        />
                                      ) : null}
                                    </span>
                                  </div>
                                </th>
                              )}
                            </>
                          );
                        }
                      )}
                    </tr>
                  </thead>
                  <tbody>
                    {/* Mapping through bankData array to render rows */}
                    {searchedBanks.map((row, index) => (
                      <tr key={index}>
                        {/* Displaying bank data in respective columns */}
                        {!isMobile ? (
                          <td>
                            <span className={styles.fdRank}>{row.rank1}</span>
                          </td>
                        ) : null}

                        <td className={styles.fixedColumn}>
                          <div className={styles.card}>
                            <Link
                              to={row.url}
                              target="_blank"
                              id="my-anchor-element"
                            >
                              <div className={styles.img}>
                                <img
                                  width="30x"
                                  height="30px"
                                  src={`../bankLogos/${row.bcode}.png`}
                                  alt=""
                                  loading="lazy"
                                ></img>
                              </div>
                            </Link>

                            <div className={styles.textBox}>
                              {row.type === "NBFC" ? (
                                <div className={styles.highlighterDiv}>
                                  <div
                                    className={styles.highlighter}
                                    aria-hidden="true"
                                  >
                                    {`${row.Agency || row.Agency1} ${
                                      row.Rating || row.Rating1
                                    }/${row.Outlook || row.Outlook1}`}
                                  </div>
                                </div>
                              ) : null}

                              <div className={styles.textContent}>
                                <Link
                                  to={row.url}
                                  target="_blank"
                                  id="my-anchor-element"
                                >
                                  <Tooltip
                                    content="Go to Bank Website"
                                    showArrow={true}
                                  >
                                    <Typography
                                      // component="p"
                                      className={styles.h1}
                                      sx={{
                                        fontSize: "17px",
                                        color: "#202124",
                                        marginRight:
                                          visibleColumns.has("1") &&
                                          visibleColumns.has("2")
                                            ? "15px"
                                            : "0",
                                      }}
                                    >
                                      {isMobile ? row.mobile_name : row.name}
                                    </Typography>
                                  </Tooltip>
                                </Link>
                              </div>

                              <Typography
                                // component="span"
                                variant="subtitle2"
                                sx={{
                                  mb: "5px",
                                  mt: "-2px",
                                  color: "#1a73e8",
                                  fontSize: "0.8rem",
                                }}
                              >
                                <Link
                                  target="_blank"
                                  to={`/bankDetails/${row.name}`}
                                >
                                  Details
                                </Link>
                              </Typography>
                            </div>
                          </div>
                        </td>

                        {showRegularRate ? (
                          <td>
                            <div className={styles.seniorRate1}>
                              <div className={styles.RateAndTenor}>
                                {row.fdrate.toFixed(2)}%
                              </div>
                            </div>
                          </td>
                        ) : null}
                        {showSeniorCitizen ? (
                          <td>
                            <div className={styles.seniorRate}>
                              <span className={styles.RateAndTenor1}>
                                {row.addsenior.toFixed(2)}%
                              </span>
                              <div className={styles.subTenor}>
                                <small className={styles.daysToYear}>
                                  {`Base +  ${" "}  ${(
                                    row.addsenior - row.fdrate
                                  ).toFixed(2)}%`}
                                </small>{" "}
                              </div>
                            </div>
                          </td>
                        ) : null}

                        {showSupSeniorCitizen ? (
                          <td>
                            <div className={styles.seniorRate}>
                              <span className={styles.RateAndTenor1}>
                                {row.addsupsenior.toFixed(2)}%
                              </span>
                              <div className={styles.subTenor}>
                                <small className={styles.daysToYear}>
                                  {`Base +${" "}  ${(
                                    row.addsupsenior - row.fdrate
                                  ).toFixed(2)}%`}
                                </small>{" "}
                              </div>
                            </div>
                          </td>
                        ) : null}

                        <td>
                          <div className={styles.Tenor}>
                            {row.tenorstart === row.tenorend ? (
                              row.tenorstart.includes("d") ||
                              row.tenorstart.includes("mo") ? (
                                <>
                                  {" "}
                                  {removeSpaces(row.tenorstart)}
                                  <div className={styles.subTenor1}>
                                    <span className={styles.daysToYear}>
                                      ~
                                      {getYearsFromDays(
                                        parseInt(row.tenorstartday)
                                      ) + " years"}
                                    </span>{" "}
                                  </div>
                                </>
                              ) : (
                                <>
                                  {" "}
                                  {removeSpaces(row.tenorstart)}
                                  <div className={styles.subTenor1}>
                                    <span className={styles.daysToYear}></span>{" "}
                                  </div>
                                </>
                              )
                            ) : (
                              <>
                                {" "}
                                {removeSpaces(row.tenorstart)} -{" "}
                                {removeSpaces(row.tenorend)}
                                <div className={styles.subTenor1}>
                                  <span className={styles.daysToYear}></span>{" "}
                                </div>
                              </>
                            )}
                          </div>
                        </td>
                        <td>
                          <div className={styles.bankTypeRow}>
                            <>
                              {" "}
                              {row.type}
                              <div className={styles.subTenor1}>
                                <span className={styles.daysToYear}></span>{" "}
                              </div>
                            </>
                          </div>
                        </td>
                        <td className={styles.bankDetails2}>
                          <div className={styles.bankTypeRow}>
                            <>
                              {" "}
                              {row.effdate}
                              <div className={styles.subTenor1}>
                                <span className={styles.daysToYear}></span>{" "}
                              </div>
                            </>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            )}

            {!loader &&
            (isAllChecked || isMajorChecked || selectedFilters.length > 0) &&
            filteredItems.length > 0 ? (
              <div className={styles.features}>
                {/* <h4>Features and Benefits of {fdData[0].name} FD</h4> */}
                <div className={styles.featuresList}>
                  <ul>
                    <li aria-level="1">
                      <span>1. Data source: Official bank websites.</span>
                    </li>
                    <li aria-level="1">
                      <span>
                        2. Rates are displayed for retail callable deposits{" "}
                        {"<"} Rs 3cr, unless otherwise specificed on the
                        "Details" page of banks.
                      </span>
                    </li>
                    <li aria-level="1">
                      <span>
                        3. NBFC deposits are not covered by DICGC insurance of
                        Rs 5 lakhs.
                      </span>
                    </li>
                    <li aria-level="1">
                      <span>
                        4. Site last updated: {`${lastUpdateDate()}`}.
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </>
  );
}

export default FdTable;
