import { Link } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import VisibilityTwoToneIcon from "@mui/icons-material/VisibilityTwoTone";
import VisibilityOffTwoToneIcon from "@mui/icons-material/VisibilityOffTwoTone";
import { useDispatch, useSelector } from "react-redux";
import { login, googleLogin } from "../../../redux/slice/authSlice";
import GoogleLoginButton from "../GoogleLoginButton";
import { clearError } from "../../../redux/slice/authSlice";

function SignIn() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [googleEmail, setGoogleEmail] = useState("");
  const [responseError, setResponseError] = useState(null);
  const dispatch = useDispatch();
  const { loading, error, isAuthenticated, errorType } = useSelector(
    (state) => state.auth
  );
  // console.log("error", errorType);
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    await dispatch(login({ email, password }));
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "email") {
      setEmail(value);
    } else if (name === "password") {
      setPassword(value);
    }

    dispatch(clearError());
  };

  useEffect(() => {
    if (isAuthenticated === true) {
      navigate("/");
    } else {
      setResponseError(error);
    }
  }, [handleSubmit, navigate, isAuthenticated, error]);

  useEffect(() => {
    // Clear error on component mount
    dispatch(clearError());
  }, [dispatch]);

  return (
    <section class="bg-gray-50 D1:bg-gray-900">
      <div class="flex flex-col items-center mt-20 px-6 py-8 mx-auto md:h-screen lg:py-0">
        {/* <div>
            <img
              src="https://storage.googleapis.com/devitary-image-host.appspot.com/15846435184459982716-LogoMakr_7POjrN.png"
              className="w-32 mx-auto"
            />
          </div> */}
        <div className="w-full bg-white rounded-lg shadow D1:border md:mt-0 sm:max-w-md D1:bg-gray-800 D1:border-gray-700 sm:p-8">
          <div className="flex justify-center">
            <h1 className="text-2xl xl:text-3xl font-extrabold ">
              Sign in to your account
            </h1>
          </div>
          <div className="">
            <GoogleLoginButton setResponseError={setResponseError} />
          </div>
          {/* <button onClick={handleGoogleLogout}>logout</button> */}

          <form onSubmit={handleSubmit} className="w-full flex-1 mt-2">
            {/* <div className="flex flex-col items-center">
              <button className="w-full max-w-xs font-bold shadow-sm rounded-lg py-3 bg-indigo-100 text-gray-800 flex items-center justify-center transition-all duration-300 ease-in-out focus:outline-none hover:shadow focus:shadow-sm focus:shadow-outline">
                <div className="bg-white p-2 rounded-full">
                  <svg className="w-4" viewBox="0 0 533.5 544.3">
                    <path
                      d="M533.5 278.4c0-18.5-1.5-37.1-4.7-55.3H272.1v104.8h147c-6.1 33.8-25.7 63.7-54.4 82.7v68h87.7c51.5-47.4 81.1-117.4 81.1-200.2z"
                      fill="#4285f4"
                    />
                    <path
                      d="M272.1 544.3c73.4 0 135.3-24.1 180.4-65.7l-87.7-68c-24.4 16.6-55.9 26-92.6 26-71 0-131.2-47.9-152.8-112.3H28.9v70.1c46.2 91.9 140.3 149.9 243.2 149.9z"
                      fill="#34a853"
                    />
                    <path
                      d="M119.3 324.3c-11.4-33.8-11.4-70.4 0-104.2V150H28.9c-38.6 76.9-38.6 167.5 0 244.4l90.4-70.1z"
                      fill="#fbbc04"
                    />
                    <path
                      d="M272.1 107.7c38.8-.6 76.3 14 104.4 40.8l77.7-77.7C405 24.6 339.7-.8 272.1 0 169.2 0 75.1 58 28.9 150l90.4 70.1c21.5-64.5 81.8-112.4 152.8-112.4z"
                      fill="#ea4335"
                    />
                  </svg>
                </div>
                <span className="ml-4">Sign In with Google</span>
              </button>     
            </div> */}

            <div className="my-5 border-b text-center">
              <div className="leading-none px-2 inline-block text-sm text-gray-600 tracking-wide font-medium bg-white transform translate-y-1/2">
                Or sign In with e-mail
              </div>
            </div>

            <div className="mx-auto max-w-xs">
              <input
                className="w-full px-8 py-4 rounded-lg font-medium bg-gray-100 border border-gray-200 placeholder-gray-500 text-sm focus:outline-none focus:border-gray-400 focus:bg-white mt-5"
                type="email"
                value={email}
                onChange={handleChange}
                placeholder="Email"
                name="email"
                required
              />
              <div className="relative mt-5 mb-2">
                <input
                  className="w-full px-8 py-4 rounded-lg font-medium bg-gray-100 border border-gray-200 placeholder-gray-500 text-sm focus:outline-none focus:border-gray-400 focus:bg-white"
                  type={showPassword ? "text" : "password"}
                  onChange={handleChange}
                  placeholder="Password"
                  value={password}
                  name="password"
                  required
                />

                <button
                  type="button"
                  className="absolute inset-y-0 right-3 flex items-center text-gray-500"
                  onClick={() => setShowPassword((prev) => !prev)}
                >
                  {showPassword ? (
                    <VisibilityOffTwoToneIcon />
                  ) : (
                    <VisibilityTwoToneIcon />
                  )}
                </button>
              </div>
              <p
                id="standard_error_help"
                class="mt-2 text-xs text-red-600 D1:text-red-400"
              >
                {errorType !== "google_error" && error}
              </p>
              <Link
                to={"/forgot-password"}
                className="text-sm  font-medium text-primary-600 hover:underline D1:text-primary-500"
              >
                Forgot password?
              </Link>

              <button
                // onClick={handleSubmit}
                className="mt-5 tracking-wide font-semibold bg-blue-700 text-gray-100 w-full py-4 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 transition-all duration-300 ease-in-out flex items-center justify-center focus:shadow-outline focus:outline-none"
              >
                {" "}
                {loading ? (
                  <>
                    <div className="animate-spin rounded-full h-5 w-5 border-b-2 border-white mr-3"></div>
                    Signing...
                  </>
                ) : (
                  <>
                    <svg
                      className="w-6 h-6 -ml-2"
                      fill="none"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    >
                      <path d="M16 21v-2a4 4 0 00-4-4H5a4 4 0 00-4 4v2" />
                      <circle cx="8.5" cy="7" r="4" />
                      <path d="M20 8v6M23 11h-6" />
                    </svg>
                    <span className="ml-3">Sign In</span>
                  </>
                )}
              </button>
              {/* <p className="mt-6 text-xs text-gray-600 text-center">
                  I agree to abide by templatana's
                  <a
                    href="#"
                    className="border-b border-gray-500 border-dotted"
                  >
                    Terms of Service
                  </a>
                  and its
                  <a
                    href="#"
                    className="border-b border-gray-500 border-dotted"
                  >
                    Privacy Policy
                  </a>
                </p> */}
              <p class=" mt-4 text-sm font-light text-gray-500 D1:text-gray-400">
                Not registered?{" "}
                <Link
                  to={"/signup"}
                  className="  font-medium text-primary-600 hover:underline D1:text-primary-500"
                >
                  Create an account.
                </Link>
              </p>
            </div>
          </form>
        </div>
      </div>
    </section>
  );
}

export default SignIn;
