import axios from "axios";
import store from "../redux/store";
import { refreshAccessToken, logout } from "../redux/slice/authSlice";

const api = axios.create({
  baseURL: "https://backend.wanij.com",
});

api.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;
    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      try {
        await store.dispatch(refreshAccessToken()).unwrap();
        const newTokens = store.getState().auth.tokens;
        originalRequest.headers["Authorization"] = `Bearer ${newTokens.access}`;
        return api(originalRequest);
      } catch (refreshError) {
        store.dispatch(logout());
        return Promise.reject(refreshError);
      }
    }
    return Promise.reject(error);
  }
);

export default api;
