import React, { useEffect, useState, useRef } from "react";
import DOMPurify from "dompurify";
import "./blog.css";
import { useParams } from "react-router-dom";
import { User } from "@nextui-org/user";
import { Divider } from "@nextui-org/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faComments } from "@fortawesome/free-regular-svg-icons";
import { faThumbsUp } from "@fortawesome/free-regular-svg-icons";
import axios from "axios";
import Spinner from "../Spinners/Spinner";
import Comments from "./lib/Comments";
import RecommandedBlog from "./lib/RecommandedBlog";

import ResponsiveComponent from "../../utils/ResponsiveLayoutSwitch";
import ShareOnMobile from "./lib/share/ShareOnMobile";
import ShareOnDesktop from "./lib/share/ShareOnDesktop";

function BlogSinglePost() {
  const [blog, setBlog] = useState(null);
  const [media, setMediaUrl] = useState(null);
  const [commentCount, setCommentCount] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isRemLoading, setIsRemLoading] = useState(true);
  const [error, setError] = useState(null);
  const { id } = useParams();
  const scrollRef = useRef(null);

  const [allBlogs, setAllBlogs] = useState([]);

  const fetchAllBlog = async () => {
    setIsRemLoading(true);
    try {
      const response = await fetch(
        "https://wp.wanij.com/wordpress/index.php?rest_route=/wp/v2/posts"
      );
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      setAllBlogs(data);
    } catch (error) {
      console.error("Fetching error:", error);
      // Handle or set error state if necessary
    } finally {
      setIsRemLoading(false);
    }
  };

  useEffect(() => {
    fetchAllBlog();
  }, []);

  useEffect(() => {
    const fetchBlog = async () => {
      setIsLoading(true);
      try {
        const postUrl = `https://wp.wanij.com/wordpress/index.php?rest_route=/wp/v2/posts/${id}`;
        const postResponse = await fetch(postUrl);
        if (!postResponse.ok) {
          throw new Error(`HTTP error! status: ${postResponse.status}`);
        }
        const postData = await postResponse.json();

        setBlog(postData);

        // Extract the media ID from the post data
        const { featured_media, author } = postData;

        if (featured_media && author) {
          // Fetch the media details if there is a media ID
          const getImageUrl = await axios.get(
            `https://wp.wanij.com/wordpress/index.php?rest_route=/wp/v2/media/${featured_media}`
          );

          const getAuthor = await axios.get(
            `https://wp.wanij.com/wordpress/index.php?rest_route=/wp/v2/users/${author}`
          );

          Promise.all([getImageUrl, getAuthor])
            .then((res) => {
              setMediaUrl({
                imgUrl: res[0].data.guid.rendered,
                author: res[1].data.name,
                authorImg: res[1].data.avatar_urls[48],
                isLoaded: true,
              });
            })
            .catch((err) => {});
        }
      } catch (e) {
        console.error("Fetching error:", e);
        setError("Failed to fetch blog post or media. Please try again later.");
      } finally {
        setIsLoading(false);
      }
    };

    fetchBlog();
  }, [id]);

  const scrollToSection = () => {
    scrollRef.current.scrollIntoView({ behavior: "smooth" });
  };

  if (isLoading)
    return (
      <>
        <Spinner />
      </>
    );
  if (error) return <h1>{error}</h1>;
  if (!blog) return <h1>No blog post found</h1>;

  const sanitizedContent = DOMPurify.sanitize(blog.content.rendered);

  return (
    <div className="w-full max-w-4xl mx-auto px-4 sm:px-6 lg:px-8">
      <div className="bg-background/60 D:bg-default-100/50 rounded-lg shadow-none overflow-hidden">
        <div className="p-6 sm:p-8 md:p-10">
          <h1 className="text-2xl sm:text-3xl md:text-4xl font-bold mb-5 ">
            {blog.title.rendered}
          </h1>
          <div className="flex justify-between items-start">
            <div className="flex flex-col gap-0 mb-0">
              <User
                name={media.author}
                description={new Date(blog.date).toLocaleDateString("en-US", {
                  year: "numeric",
                  month: "long",
                  day: "numeric",
                })}
                avatarProps={{
                  src: `${media.authorImg}`,
                }}
              />
            </div>
          </div>
          <Divider className="my-5 mb-2" />
          <div className="flex justify-between items-start">
            <div className="flex mb-0">
              {" "}
              {/* <p className="text-bold  capitalize  mr-5 mb-2">
                <button>
                  {" "}
                  <FontAwesomeIcon icon={faThumbsUp} className="text-lg" />
                  <span className="ml-0.5 text-sm text-default-400"> 25k</span>
                </button>
              </p> */}
              <p className="text-bold  capitalize  mr-3 mb-2">
                <button onClick={scrollToSection} className="flex items-center">
                  <svg
                    class="w-6 h-6 text-gray-600 D:text-white"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <path
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M9 17h6l3 3v-3h2V9h-2M4 4h11v8H9l-3 3v-3H4V4Z"
                    />
                  </svg>

                  <span className="ml-0.5 text-sm text-default-400">
                    {" "}
                    {commentCount}
                  </span>
                </button>
              </p>
            </div>
            <ResponsiveComponent
              blog={blog}
              imageUrl={media.imagUrl}
              MobileComponent={ShareOnMobile}
              DesktopComponent={ShareOnDesktop}
            />
          </div>

          <Divider className="my-10 mt-0" />
          <div className="flex justify-center items-center mb-5 bg-gray-100 rounded-lg ">
            <img
              src={media.imgUrl}
              alt={"image"}
              className="max-w-full h-auto rounded-lg shadow-md"
            />
          </div>

          <div
            className="prose prose-sm sm:prose lg:prose-lg mx-auto"
            dangerouslySetInnerHTML={{ __html: sanitizedContent }}
          />
        </div>
      </div>
      <div ref={scrollRef}>
        <Comments postId={blog.id} setCommentCount={setCommentCount} />
      </div>

      <RecommandedBlog key={blog.id} blog={blog} allBlog={allBlogs} />
    </div>
  );
}

export default BlogSinglePost;
