import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import VisibilityTwoToneIcon from "@mui/icons-material/VisibilityTwoTone";
import VisibilityOffTwoToneIcon from "@mui/icons-material/VisibilityOffTwoTone";

const API_URL = `${process.env.REACT_APP_API_URL}`;

// Password validation functions
const passwordValidations = {
  hasUpperCase: (password) => /[A-Z]/.test(password),
  hasNumber: (password) => /[0-9]/.test(password),
  hasMinLength: (password) => password.length >= 8,
  hasSpecialChar: (password) => /[!@#$%^&*(),.?":{}|<>]/.test(password),
};

const validatePassword = (password) => {
  const validations = {
    uppercase: !passwordValidations.hasUpperCase(password)
      ? "Password must contain at least one uppercase character"
      : "",
    number: !passwordValidations.hasNumber(password)
      ? "Password must contain at least one number"
      : "",
    length: !passwordValidations.hasMinLength(password)
      ? "Password must be at least 8 characters long"
      : "",
    special: !passwordValidations.hasSpecialChar(password)
      ? "Password must contain at least one special character"
      : "",
  };

  return Object.values(validations).find((error) => error) || "";
};

function ResetPassword() {
  const { uid, token } = useParams();
  const [password, setPassword] = useState("");
  const [status, setStatus] = useState({ type: "", message: "" });
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [touched, setTouched] = useState(false);
  const navigate = useNavigate();

  // Check if password meets all requirements
  const passwordStrength = {
    uppercase: passwordValidations.hasUpperCase(password),
    number: passwordValidations.hasNumber(password),
    length: passwordValidations.hasMinLength(password),
    special: passwordValidations.hasSpecialChar(password),
  };

  // Determine if form can be submitted
  const canSubmit =
    Object.values(passwordStrength).every(Boolean) &&
    touched &&
    !validatePassword(password);

  const handleBlur = () => {
    setTouched(true);
    const error = validatePassword(password);
    if (error) {
      setStatus({ type: "error", message: error });
    } else {
      setStatus({ type: "", message: "" });
    }
  };

  const handleChange = (e) => {
    const newPassword = e.target.value;
    setPassword(newPassword);
    if (touched) {
      const error = validatePassword(newPassword);
      if (error) {
        setStatus({ type: "error", message: error });
      } else {
        setStatus({ type: "", message: "" });
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!canSubmit) {
      return;
    }

    setLoading(true);
    try {
      const response = await fetch(`${API_URL}/reset-password/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          uid,
          token,
          new_password: password,
        }),
      });

      if (response.ok) {
        setStatus({
          type: "success",
          message: "Password has been reset successfully.",
        });
        setPassword("");
        setTimeout(() => {
          navigate("/signin");
        }, 1000);
      } else {
        throw new Error("Failed to reset password");
      }
    } catch (error) {
      setStatus({
        type: "error",
        message: "An error occurred. Please try again later.",
      });
    } finally {
      setLoading(false);
    }
  };

  if (!uid || !token) {
    return "Invalid reset link. Please request a new password reset link.";
  }

  return (
    <section className="bg-gray-50 D1:bg-gray-900">
      <div className="flex flex-col items-center mt-40 px-6 py-8 mx-auto md:h-screen lg:py-0">
        <div className="w-full bg-white rounded-lg shadow D1:border md:mt-0 sm:max-w-md D1:bg-gray-800 D1:border-gray-700 sm:p-8">
          <h2 className="mb-1 text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl D1:text-white">
            Set New Password
          </h2>
          <form
            onSubmit={handleSubmit}
            className="mt-4 space-y-4 lg:mt-5 md:space-y-5"
          >
            <div className="relative mt-5 mb-2">
              <input
                className={`w-full px-8 py-4 rounded-lg font-medium bg-gray-100 border ${
                  touched && status.type === "error"
                    ? "border-red-500"
                    : "border-gray-200"
                } placeholder-gray-500 text-sm focus:outline-none focus:border-gray-400 focus:bg-white`}
                type={showPassword ? "text" : "password"}
                onChange={handleChange}
                onBlur={handleBlur}
                placeholder="Password"
                value={password}
              />
              <button
                type="button"
                className="absolute inset-y-0 right-3 flex items-center text-gray-500"
                onClick={() => setShowPassword((prev) => !prev)}
              >
                {showPassword ? (
                  <VisibilityOffTwoToneIcon />
                ) : (
                  <VisibilityTwoToneIcon />
                )}
              </button>
            </div>
            <p
              className={`mt-2 text-xs ${
                status.type === "error"
                  ? "text-red-600"
                  : status.type === "success"
                  ? "text-green-600"
                  : "text-gray-700"
              } D1:text-red-400`}
            >
              {status.message}
            </p>
            <div className="space-y-1 text-sm text-gray-500">
              <p className="text-sm mt-5">Password must contain:</p>
              <ul className="list-disc list-inside space-y-1">
                <li
                  className={passwordStrength.uppercase ? "text-green-600" : ""}
                >
                  One uppercase character {passwordStrength.uppercase && "✓"}
                </li>
                <li className={passwordStrength.number ? "text-green-600" : ""}>
                  One number {passwordStrength.number && "✓"}
                </li>
                <li
                  className={passwordStrength.special ? "text-green-600" : ""}
                >
                  One special character {passwordStrength.special && "✓"}
                </li>
                <li className={passwordStrength.length ? "text-green-600" : ""}>
                  Minimum 8 characters {passwordStrength.length && "✓"}
                </li>
              </ul>
            </div>

            <div className="flex items-start">
              <div className="flex items-center h-5">
                <input
                  id="newsletter"
                  aria-describedby="newsletter"
                  type="checkbox"
                  className="w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-primary-300 D1:bg-gray-700 D1:border-gray-600 D1:focus:ring-primary-600 D1:ring-offset-gray-800"
                  required=""
                  defaultChecked
                />
              </div>
              <div className="ml-3 text-sm">
                <label
                  htmlFor="newsletter"
                  className="font-light text-gray-500 D1:text-gray-300"
                >
                  I accept the{" "}
                  <a
                    className="font-medium text-primary-600 hover:underline"
                    href="#"
                  >
                    Terms and Conditions
                  </a>
                </label>
              </div>
            </div>
            <button
              type="submit"
              disabled={!canSubmit}
              className={`mt-5 tracking-wide font-semibold w-full py-4 rounded-lg transition-all duration-300 ease-in-out flex items-center justify-center focus:shadow-outline focus:outline-none ${
                canSubmit
                  ? "bg-blue-700 text-gray-100 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300"
                  : "bg-gray-300 text-gray-500 cursor-not-allowed"
              }`}
            >
              <span className="ml-3">
                {loading ? "Resetting..." : "Reset Password"}
              </span>
            </button>
          </form>
        </div>
      </div>
    </section>
  );
}

export default ResetPassword;
