import React, { useState } from "react";
import { Link } from "react-router-dom";
import { lastUpdateDate } from "../../utils/lastUpdateDate";
import { Typography } from "@mui/material";
import AccountBalanceRoundedIcon from "@mui/icons-material/AccountBalanceRounded";
import SavingsRoundedIcon from "@mui/icons-material/SavingsRounded";
import { Helmet } from "react-helmet";
import { getPosts } from "../../utils/lastUpdateDate";

function Home() {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 614);
  const [getPosts1, setGetPosts] = useState(getPosts() || []);

  return (
    <>
      <Helmet>
        ‍<title>Wanij.com</title>‍
        <meta
          name="description"
          content="Explore India’s top interest rates."
        />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@user" />
        <meta name="twitter:creator" content="@user" />
        <meta name="twitter:title" content="Pets - Products" />
        <meta name="twitter:description" content="Best FD rates" />
        <meta name="twitter:image" content="url_to_image" />
        <meta property="og:title" content="Pets - Products" />
        <meta property="og:description" content="Best Savings interest rates" />
        <meta property="og:image" content="www.wanij.com" />
        <meta property="og:url" content="www.wanij.com" />
        <meta property="og:Wanij" content="www.wanij.com" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="article" />
        <meta property="fb:app_id" content="ID_APP_FACEBOOK" />
      </Helmet>

      <div className="flex flex-col justify-center items-center bg-gradient-to-r from-[#153567] via-[#061d4b] to-[#00143d] pb-12">
        <div className="my-2 mt-20 text-center">
          <h1 className="text-white text-4xl leading-tight mb-4 font-semibold">
            {isMobile
              ? "Explore India’s top interest rates."
              : "Explore India’s top interest rates."}
          </h1>
        </div>
        <div className="flex flex-col justify-center items-center">
          <div className="flex justify-center items-center flex-wrap gap-5 m-5">
            <Link to="/FDpage">
              <div className="p-4 bg-white w-64 rounded-lg hover:transform hover:-translate-x-1.5 hover:-translate-y-1.5 transition-all duration-800 ease-[cubic-bezier(0.1,0.78,0.61,1)]">
                <div className="flex flex-col justify-center items-center">
                  <AccountBalanceRoundedIcon
                    sx={{ width: "45px", height: "45px" }}
                  />
                  <p className="font-bold text-[#00143d] text-2xl leading-tight tracking-tight mt-2 mb-2">
                    Fixed Deposits
                  </p>
                </div>
              </div>
            </Link>
            <Link to="/savingsPage">
              <div className="p-4 bg-white w-64 rounded-lg hover:transform hover:-translate-x-1.5 hover:-translate-y-1.5 transition-all duration-800 ease-[cubic-bezier(0.1,0.78,0.61,1)]">
                <div className="flex flex-col justify-center items-center">
                  <SavingsRoundedIcon sx={{ width: "45px", height: "45px" }} />
                  <p className="font-bold text-[#00143d] text-2xl leading-tight tracking-tight mt-2 mb-2">
                    Savings Deposits
                  </p>
                </div>
              </div>
            </Link>
          </div>
          <div className="flex flex-col justify-center items-center font-medium text-[#efff] text-sm tracking-widest text-center ">
            <Typography
              sx={{ margin: "10px" }}
              className="mt-1  text-lg text-[#efff] font-normal tracking-widest text-center px-4"
            >
              Our exhaustive comparison covers all major Indian banks, NBFCs and
              post-office schemes.
            </Typography>
            <Typography sx={{ color: "#efff", textAlign: "center" }}>
              Rates last updated: {lastUpdateDate()}
            </Typography>
          </div>
        </div>
      </div>
    </>
  );
}

export default Home;
