import React from "react";
import { shareOnMobile } from "react-mobile-share";

function ShareOnMobile() {
  const url = window.location.href;
  const handleShareOnMobile = () => {
    shareOnMobile({ title: "Wanij Blog Share", url: url });
    //
  };
  return (
    <div>
      <button onClick={handleShareOnMobile}>
        <div className="mr-2">
          <svg
            class="w-6 h-6 text-gray-800 D:text-white"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            fill="none"
            viewBox="0 0 24 24"
          >
            <path
              stroke="currentColor"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="m15.141 6 5.518 4.95a1.05 1.05 0 0 1 0 1.549l-5.612 5.088m-6.154-3.214v1.615a.95.95 0 0 0 1.525.845l5.108-4.251a1.1 1.1 0 0 0 0-1.646l-5.108-4.251a.95.95 0 0 0-1.525.846v1.7c-3.312 0-6 2.979-6 6.654v1.329a.7.7 0 0 0 1.344.353 5.174 5.174 0 0 1 4.652-3.191l.004-.003Z"
            />
          </svg>
        </div>
      </button>
    </div>
  );
}

export default ShareOnMobile;
