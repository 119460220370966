import React, { useEffect, useState, useMemo } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import {
  Card,
  CardBody,
  Image,
  Button,
  Slider,
  CardFooter,
} from "@nextui-org/react";
import { User } from "@nextui-org/user";
import { Divider } from "@nextui-org/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faComments } from "@fortawesome/free-regular-svg-icons";
import { faThumbsUp } from "@fortawesome/free-regular-svg-icons";
import Spinner from "../../Spinners/Spinner";

function RecommandedBlog({ blog, allBlog }) {
  //
  const [liked, setLiked] = React.useState(false);
  const [media, setMedia] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const RecBlogs = useMemo(() => {
    return allBlog.filter((b) => b.id !== blog.id).slice(0, 4);
  }, [allBlog, blog.id]);
  //
  //
  const [blogDetail, setBlogDetail] = useState({
    imgUrl: "",
    author: "",
    isLoaded: false,
  });

  useEffect(() => {
    const fetchAllBlog = async () => {
      setIsLoading(true);
      try {
        const response = await fetch(
          "https://wp.wanij.com/wordpress/index.php?rest_route=/wp/v2/media"
        );
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();
        setMedia(data);
      } catch (error) {
        console.error("Fetching error:", error);
        // Handle or set error state if necessary
      } finally {
        setIsLoading(false);
      }
    };
    fetchAllBlog();
  }, [blog.id]);

  return (
    <aside
      aria-label="Related articles"
      className="py-2 px-4 pb-8 mt-8 lg:py-2:mt-8 lg:pb-16 bg-gray-50 D1:bg-gray-800"
    >
      <div className="px-4 mx-auto max-w-screen-xl">
        <h2 className="mb-8 text-2xl font-bold text-gray-900 D1:text-white">
          Related articles
        </h2>
        <div className="grid gap-12 sm:grid-cols-2 lg:grid-cols-4">
          {RecBlogs.map((blog, index) => {
            const imageUrl = media.find((m) => m.id === blog.featured_media);

            if (index > 3) return null;
            else {
              return (
                <article className="max-w-xs">
                  <Link to={`/blog/${blog.id}`}>
                    <img
                      src={imageUrl?.guid?.rendered}
                      className="mb-5 rounded-lg w-full h-52 object-fit"
                      alt="Image 1"
                    />
                  </Link>
                  <h2
                    className="mb-2 text-xl font-bold leading-tight text-gray-900 D1:text-white"
                    style={{
                      display: "-webkit-box",
                      WebkitLineClamp: 2,
                      WebkitBoxOrient: "vertical",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      whiteSpace: "normal",
                    }}
                  >
                    <a href="#">{blog.title.rendered}</a>
                  </h2>
                  <p
                    className="mb-4 text-gray-500 D1:text-gray-400"
                    style={{
                      fontFamily:
                        " Inter, ui-sans-serif, system-ui, -apple-system, system-ui,Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif,Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji",
                      // lineHeight: "20px",
                      color: "#6B6B6B",
                      fontWeight: "500",
                      fontSize: "16px",
                      marginBottom: "0.5rem",
                      letterSpacing: "-0.2px",
                      display: "-webkit-box",
                      WebkitLineClamp: 2,
                      WebkitBoxOrient: "vertical",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      whiteSpace: "normal",
                    }}
                  >
                    {blog.excerpt.rendered}
                  </p>
                  <a
                    href="#"
                    className="inline-flex items-center font-medium underline underline-offset-4 text-primary-600 D1:text-primary-500 hover:no-underline"
                  >
                    Read more
                  </a>
                </article>
              );
            }
          })}

          {/* <article className="max-w-xs">
            <a href="#">
              <img
                src="https://flowbite.s3.amazonaws.com/blocks/marketing-ui/article/blog-2.png"
                className="mb-5 rounded-lg"
                alt="Image 2"
              />
            </a>
            <h2 className="mb-2 text-xl font-bold leading-tight text-gray-900 D1:text-white">
              <a href="#">Enterprise design tips</a>
            </h2>
            <p className="mb-4  text-gray-500 D1:text-gray-400">
              Over the past year, Volosoft has undergone many changes! After
              months of preparation.
            </p>
            <a
              href="#"
              className="inline-flex items-center font-medium underline underline-offset-4 text-primary-600 D1:text-primary-500 hover:no-underline"
            >
              Read in 12 minutes
            </a>
          </article>
          <article className="max-w-xs">
            <a href="#">
              <img
                src="https://flowbite.s3.amazonaws.com/blocks/marketing-ui/article/blog-3.png"
                className="mb-5 rounded-lg"
                alt="Image 3"
              />
            </a>
            <h2 className="mb-2 text-xl font-bold leading-tight text-gray-900 D1:text-white">
              <a href="#">We partnered with Google</a>
            </h2>
            <p className="mb-4  text-gray-500 D1:text-gray-400">
              Over the past year, Volosoft has undergone many changes! After
              months of preparation.
            </p>
            <a
              href="#"
              className="inline-flex items-center font-medium underline underline-offset-4 text-primary-600 D1:text-primary-500 hover:no-underline"
            >
              Read in 8 minutes
            </a>
          </article>
          <article className="max-w-xs">
            <a href="#">
              <img
                src="https://flowbite.s3.amazonaws.com/blocks/marketing-ui/article/blog-4.png"
                className="mb-5 rounded-lg"
                alt="Image 4"
              />
            </a>
            <h2 className="mb-2 text-xl font-bold leading-tight text-gray-900 D1:text-white">
              <a href="#">Our first project with React</a>
            </h2>
            <p className="mb-4  text-gray-500 D1:text-gray-400">
              Over the past year, Volosoft has undergone many changes! After
              months of preparation.
            </p>
            <a
              href="#"
              className="inline-flex items-center font-medium underline underline-offset-4 text-primary-600 D1:text-primary-500 hover:no-underline"
            >
              Read in 4 minutes
            </a>
          </article> */}
        </div>
      </div>
    </aside>
  );
}

export default RecommandedBlog;
