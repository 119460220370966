import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import {
  Card,
  CardBody,
  Image,
  Button,
  Slider,
  CardFooter,
} from "@nextui-org/react";
import { User } from "@nextui-org/user";
import { Divider } from "@nextui-org/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faComments } from "@fortawesome/free-regular-svg-icons";
import { faThumbsUp } from "@fortawesome/free-regular-svg-icons";

function BlogCard({ blog }) {
  const [liked, setLiked] = React.useState(false);

  const [blogDetail, setBlogDetail] = useState({
    imgUrl: "",
    author: "",
    isLoaded: false,
  });

  useEffect(() => {
    const { featured_media, author } = blog;

    const getImageUrl = axios.get(
      `https://wp.wanij.com/wordpress/index.php?rest_route=/wp/v2/media/${featured_media}`
    );

    const getAuthor = axios.get(
      `https://wp.wanij.com/wordpress/index.php?rest_route=/wp/v2/users/${author}`
    );

    const getCommentCount = axios.get(
      `https://wp.wanij.com/wordpress/index.php?rest_route=%2Fwp%2Fv2%2Fcomments&post=${blog.id}`
    );

    Promise.all([getImageUrl, getAuthor, getCommentCount])
      .then((res) => {
        //
        setBlogDetail({
          imgUrl: res[0].data.guid.rendered,
          author: res[1].data.name,
          authorImg: res[1].data.avatar_urls[48],
          commentCount: res[2].data.length,
          isLoaded: true,
        });
      })
      .catch((err) => {});

    //
  }, []);

  return (
    <Card
      isBlurred
      className="bg-transparent D:bg-default-100/50 max-w-[710px] "
      shadow="none"
    >
      <Link to={`/blog/${blog.id}`}>
        <CardBody>
          <div className="grid grid-cols-6 md:grid-cols-12 gap-6 md:gap-4 items-center justify-center">
            <div className="flex flex-col col-span-6 md:col-span-8">
              <div className="flex justify-between items-start">
                <div className="flex flex-col gap-0">
                  <User
                    name={blogDetail.author}
                    description={new Date(blog.date).toLocaleDateString(
                      "en-US",
                      {
                        year: "numeric",
                        month: "long",
                        day: "numeric",
                      }
                    )}
                    avatarProps={{
                      src: `${blogDetail.authorImg}`,
                    }}
                  />
                </div>
              </div>

              <div className="flex flex-col mt-3 ">
                <h1
                  className="text-2xl font-bold mb-2 mt-0"
                  style={{
                    fontFamily:
                      "sohne, 'Helvetica Neue', Helvetica, Arial, sans-serif",
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                    lineHeight: "28px",
                    WebkitLineClamp: "1",
                    letterSpacing: "-0.5px",
                    WebkitBoxOrient: "vertical",
                  }}
                >
                  {" "}
                  {blog.title.rendered}
                </h1>
                <div className="flex justify-between">
                  <p
                    className="text-medium text-foreground/50"
                    style={{
                      fontFamily:
                        " Inter, ui-sans-serif, system-ui, -apple-system, system-ui,Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif,Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji",
                      // lineHeight: "20px",
                      color: "#6B6B6B",
                      fontWeight: "500",
                      fontSize: "16px",
                      marginBottom: "0.5rem",
                      letterSpacing: "-0.2px",
                      display: "-webkit-box",
                      WebkitLineClamp: 2,
                      WebkitBoxOrient: "vertical",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      whiteSpace: "normal",
                    }}
                    dangerouslySetInnerHTML={{ __html: blog.excerpt.rendered }}
                  />
                </div>
                <div className="flex justify-between items-start">
                  <div className="flex mb-0">
                    {" "}
                    {/* <p className="text-bold  capitalize  mr-5 mb-2">
                      <FontAwesomeIcon icon={faThumbsUp} className="text-lg" />
                      <span className="ml-0.5 text-sm text-default-400">
                        {" "}
                        25k
                      </span>
                    </p> */}
                    <p className="text-bold  capitalize  mr-3 mb-2">
                      <FontAwesomeIcon icon={faComments} className="text-lg" />
                      <span className="ml-0.5 text-sm text-default-400">
                        {" "}
                        {blogDetail?.commentCount}
                      </span>
                    </p>
                  </div>
                  {/* <div className="mr-2">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <path
                        fill="currentColor"
                        fill-rule="evenodd"
                        d="M15.218 4.931a.4.4 0 0 1-.118.132l.012.006a.45.45 0 0 1-.292.074.5.5 0 0 1-.3-.13l-2.02-2.02v7.07c0 .28-.23.5-.5.5s-.5-.22-.5-.5v-7.04l-2 2a.45.45 0 0 1-.57.04h-.02a.4.4 0 0 1-.16-.3.4.4 0 0 1 .1-.32l2.8-2.8a.5.5 0 0 1 .7 0l2.8 2.79a.42.42 0 0 1 .068.498m-.106.138.008.004v-.01zM16 7.063h1.5a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2h-11c-1.1 0-2-.9-2-2v-10a2 2 0 0 1 2-2H8a.5.5 0 0 1 .35.15.5.5 0 0 1 .15.35.5.5 0 0 1-.15.35.5.5 0 0 1-.35.15H6.4c-.5 0-.9.4-.9.9v10.2a.9.9 0 0 0 .9.9h11.2c.5 0 .9-.4.9-.9v-10.2c0-.5-.4-.9-.9-.9H16a.5.5 0 0 1 0-1"
                        clip-rule="evenodd"
                      ></path>
                    </svg>
                  </div> */}
                </div>
              </div>
            </div>
            <div className="relative col-span-6 md:col-span-4">
              <Image
                alt="Album cover"
                className=" mb-5 rounded-lg w-full h-66  lg:h-40 object-cover"
                shadow="sm"
                src={blogDetail.imgUrl}
                width="100%"
              />
            </div>
          </div>
        </CardBody>
      </Link>
      <Divider className="my-1" />
    </Card>
  );
}

export default BlogCard;
