// utils/apiRequest.js
import axios from "axios";

export const apiRequest = async ({
  url,
  method = "POST",
  data = {},
  onSuccess,
  onError,
  setLoading,
}) => {
  if (setLoading) setLoading(true); // Set loading state

  try {
    const response = await axios({
      url,
      method,
      data,
    });

    if (onSuccess) onSuccess(response.data.message); // Call onSuccess callback
  } catch (error) {
    let errorMessage = "An error occurred. Please try again later.";

    if (error.response && error.response.data) {
      errorMessage = error.response.data.error || errorMessage;
    }

    if (onError) onError(errorMessage); // Call onError callback with the error message
  } finally {
    if (setLoading) setLoading(false); // Reset loading state
  }
};
