// src/components/PrivateRoute.js
import React from "react";
import { Route, useNavigate, Routes, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

// Function to check if the user is authenticated

const PrivateRoute = ({ children }) => {
  const { isAuthenticated, loading } = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const location = useLocation();

  if (loading) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-gray-900" />
      </div>
    );
  }

  if (!isAuthenticated) {
    // Redirect to login but save the attempted URL
    return navigate("/signin", { state: { from: location } });
  }

  return children;
};

export default PrivateRoute;
