import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
const ResponsiveComponent = ({
  MobileComponent,
  DesktopComponent,
  blog,
  imgUrl,
}) => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  //
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div>
      <Helmet>
        {/* Open Graph Meta Tags for social sharing */}
        <meta property="og:url" content={blog.link} />
        <meta property="og:type" content="article" />
        <meta property="og:title" content={blog.title.rendered} />
        <meta property="og:description" content={blog.excerpt.rendered} />
        <meta property="og:image" content={imgUrl} />

        {/* Twitter Card Meta Tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={blog.title.rendered} />
        <meta name="twitter:description" content={blog.excerpt.rendered} />
        <meta name="twitter:image" content={imgUrl} />
      </Helmet>

      {isMobile ? <MobileComponent /> : <DesktopComponent />}
    </div>
  );
};

export default ResponsiveComponent;
