import { GoogleLogin, googleLogout } from "@react-oauth/google";
import { useGoogleLogin } from "@react-oauth/google";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { googleLogin } from "../../redux/slice/authSlice";
import e from "cors";
import { useEffect } from "react";
import { set } from "react-ga";
// adjust this import as needed

function GoogleLoginButton() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { error, isAuthenticated, user, errorType } = useSelector(
    (state) => state.auth
  );
  const clientId =
    "1049121200498-mnfho130h5k9j0lkeosses63vq9fra6r.apps.googleusercontent.com";
  const handleGoogleLogin = async (googleResponse) => {
    const credential = googleResponse.credential;
    await dispatch(googleLogin(credential));
    console.log("googleUser", error);
    if (!isAuthenticated) {
      console.log("tokensssss", localStorage.getItem("tokens"));
    } else {
      navigate("/");
    }
    console.log("userssGoogle", user);
  };

  useEffect(() => {
    if (isAuthenticated === true) {
      navigate("/");
    }
  }, [navigate, isAuthenticated, error, handleGoogleLogin]);

  return (
    <div className="w-full px-8 py-4">
      <GoogleLogin
        onSuccess={handleGoogleLogin}
        clientId={clientId}
        onFailure={() => console.log("Google Login Failed")}
        cookityPolicy={"single_host_origin"}
        // isSignedIn={true} // use for auto detection of email from browser.
        onError={() => console.log("Google Login Failed")}
        size="large"
        type="standard"
        // text="signin"
        theme="filled_blue"
      />
      <p
        id="standard_error_help"
        class="mt-2 text-xs text-red-600 D1:text-red-400"
      >
        {errorType === "google_error" && error}
      </p>
    </div>
  );
}

export default GoogleLoginButton;
