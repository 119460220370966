import { useEffect } from "react";
import SavingsPage from "./SavingsPage/SavingsPage";
import Navbar from "./components/Navbar/Navbar.jsx";
import { Routes, Route, useLocation } from "react-router-dom";
import Home from "./pages/Home/Home.jsx";
import FDPage from "./FDPage/FDPage";
import Footer from "./components/footer/Footer.jsx";
import "./App.css";
import ContactUs from "./pages/ContactUsPage.jsx/ContactUs.jsx";
import ReactGA from "react-ga4";
import { InitializeGoogleAnalytics } from "./utils/googleAnalytics/googleAnalytics.js";
// import Test from "./test/Test.jsx";
import BankDetails from "./FDPage/bankDetails/BankDetails.jsx";
import Content from "./pages/Content/Content.jsx";
import BankRateDetails from "./SavingsPage/bankDetailsSavings/BankRateDetails.jsx";
import { useDispatch, useSelector } from "react-redux";
import { fetchSavings } from "./redux/slice/savingsFullRatesSlice.js";
import { fetchFdRates } from "./redux/slice/fdRateDataSlice.js";
import Privacy from "./pages/privacy/Privacy.jsx";
import TermsOfService from "./pages/T&C/TermsOfService";
import Breadcrumbs from "./components/breadcrumbs/Breadcrumbs.jsx";
import BlogSinglePost from "./components/blog/BlogSinglePost.jsx";
import BlogLanding from "./components/blog/BlogLanding.jsx";
import { GoogleOAuthProvider } from "@react-oauth/google";
import PrivateRoute from "./components/PrivateRoute.jsx";
// import Login from "./components/Login.js";
import SignUp from "./pages/Register/Login/SignUp.jsx";
import SignIn from "./pages/Register/Login/SignIn.jsx";
import ResetPassword from "./pages/Register/Login/ResetPassword.jsx";
import RequestPassword from "./pages/Register/Login/RequestPassword.jsx";
import { gapi } from "gapi-script";
import { setupAxiosInterceptors } from "./utils/authHelper.js";
import { checkAuthStatus, getUserDetails } from "./redux/slice/authSlice.js";
import Verifyemail from "./pages/Register/Verifyemail.jsx";
import UserDetails from "./components/userProfile/UserDetails.jsx";
import LoginPopup from "./components/blog/lib/LoginPopup.jsx";
import SelectBanks from "./components/blog/lib/SelectBanks.jsx";

function App() {
  const dispatch = useDispatch();
  const location = useLocation();
  const { isAuthenticated, loading, user } = useSelector((state) => state.auth);
  // useEffect(() => {
  //   setupAxiosInterceptors();
  // }, []);
  useEffect(() => {
    const tokens = localStorage.getItem("tokens");
    if (tokens && !user) {
      dispatch(getUserDetails());
    }
  }, [dispatch, user]);

  useEffect(() => {
    dispatch(fetchSavings());
    dispatch(fetchFdRates());
  }, [dispatch]);

  useEffect(() => {
    const auth = async () => await dispatch(checkAuthStatus());
  }, []);

  useEffect(() => {
    InitializeGoogleAnalytics();
    ReactGA.send({
      hitType: "pageview",
      page: window.location.pathname,
      title: "Home Page",
    });
  }, []);

  useEffect(() => {
    gapi.load("client:auth2", () => {
      gapi.auth2
        .init({
          client_id:
            "1049121200498-mnfho130h5k9j0lkeosses63vq9fra6r.apps.googleusercontent.com",
        })
        .then(() => {
          gapi.auth2.getAuthInstance().isSignedIn.listen((isSignedIn) => {
            if (isSignedIn) {
              ReactGA.send({
                hitType: "pageview",
                page: window.location.pathname,
                title: "Home Page",
              });
            }
          });
        });
    });
  }, []);

  const giveColor = () => {
    if (
      location.pathname === "/FDpage" ||
      location.pathname === "/savingsPage" ||
      location.pathname === "/signin" ||
      location.pathname === "/signup"
    ) {
      return "#f8f9fa";
    } else if (location.pathname === "/contactUs") {
      return "#f1f3f4";
    } else {
      return "#ffffff";
    }
  };

  return (
    <div className="app">
      <Navbar />
      {location.pathname !== "/" && <Breadcrumbs color={giveColor()} />}
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="FDpage" element={<FDPage />} />
        <Route path="savingsPage" element={<SavingsPage />} />
        <Route path="contactUs" element={<ContactUs />} />
        {/* <Route path="test" element={<BlogLanding />} /> */}
        <Route path="/bankDetails/:id" element={<BankDetails />} />
        <Route path="/savingsRateDetails/:id" element={<BankRateDetails />} />
        <Route path="/content" element={<Content />} />
        <Route path="/privacy" element={<Privacy />} />
        <Route path="/TandC" element={<TermsOfService />} />
        {/* <Route path="*" element={<PageNotFound />} /> */}
        <Route path="/blog" element={<BlogLanding />} />
        <Route path="/blog/:id" element={<BlogSinglePost />} />
        <Route path="/signup" element={<SignUp />} />
        <Route path="/signin" element={<SignIn />} />
        <Route path="/select-banks" element={<SelectBanks />} />
        <Route
          path="/forgot-password/:uid/:token"
          element={<ResetPassword />}
        />
        <Route path="/verify-email/:token" element={<Verifyemail />} />
        <Route
          path="/user-account"
          element={<PrivateRoute children={<UserDetails />} />}
        />

        <Route path="/user-account" element={<UserDetails />} />
        <Route path="/forgot-password/" element={<RequestPassword />} />
      </Routes>

      {/* <div className="footer"> */}
      <Footer />
      {/* </div> */}

      {/* <Test /> */}
    </div>
  );
}

export default App;
