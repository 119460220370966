import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { jwtDecode } from "jwt-decode";

const BASE_URL = `${process.env.REACT_APP_API_URL}`;

// Helper function to set auth tokens in localStorage
const setAuthTokens = (tokens) => {
  localStorage.setItem("tokens", JSON.stringify(tokens));
};

// Helper function to remove auth tokens from localStorage
const removeAuthTokens = () => {
  localStorage.removeItem("tokens");
};

// Helper function to get auth tokens from localStorage
const getAuthTokens = () => {
  const tokens = localStorage.getItem("tokens");
  return tokens ? JSON.parse(tokens) : null;
};

// Helper function to check if the access token is expired
const isTokenExpired = (token) => {
  if (!token) return true;
  const decodedToken = jwtDecode(token);
  const currentTime = Date.now() / 1000;
  //   return decodedToken.exp < currentTime;  // use this in future
  return false;
};

// Async Thunk for user registration
export const register = createAsyncThunk(
  "auth/register",
  async ({ email, password, username }, { rejectWithValue }) => {
    try {
      const response = await axios.post(`${BASE_URL}/register/`, {
        email,
        password,
        username,
      });
      // Note: We're not setting tokens here as the user still needs to log in
      setAuthTokens(response.data.tokens);
      return response.data;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue({
          error: error.response.data.error,
          error_type: error.response.data.error_type,
        });
      }
      return rejectWithValue(
        "An error occurred during registration. Please try again."
      );
    }
  }
);

// Async Thunk for login
export const login = createAsyncThunk(
  "auth/login",
  async ({ email, password }, { rejectWithValue }) => {
    try {
      const response = await axios.post(`${BASE_URL}/login/`, {
        email,
        password,
      });
      setAuthTokens(response.data.tokens);
      return response.data;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue({
          error: error.response.data.error,
          error_type: error.response.data.error_type,
        });
      }
      return rejectWithValue("An error occurred. Please try again.");
    }
  }
);

// Async Thunk for Google login
export const googleLogin = createAsyncThunk(
  "auth/googleLogin",
  async (credential, { rejectWithValue }) => {
    try {
      const response = await axios.post(`${BASE_URL}/google-login/`, {
        credential,
      });
      setAuthTokens(response.data.tokens);
      return response.data;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(
          {
            error: error.response.data.error,
            error_type: error.response.data.error_type,
          } || "An error occurred during Google login"
        );
      }
      return rejectWithValue("An unexpected error occurred");
    }
  }
);

// Async Thunk for token refresh
export const refreshAccessToken = createAsyncThunk(
  "auth/refreshToken",
  async (_, { getState, rejectWithValue }) => {
    const { refreshToken } = getState().auth.tokens;
    try {
      const response = await axios.post(`${BASE_URL}/refresh-token/`, {
        refresh: refreshToken,
      });
      const newTokens = {
        ...getState().auth.tokens,
        access: response.data.access,
      };
      setAuthTokens(newTokens);
      return newTokens;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue({
          error: error.response.data.error,
          error_type: error.response.data.error_type,
        });
      }
      return rejectWithValue("Failed to refresh token.");
    }
  }
);

export const getUserDetails = createAsyncThunk(
  "auth/getUserDetails",
  async (_, { getState, rejectWithValue }) => {
    try {
      const { tokens } = getState().auth;
      if (!tokens.access) {
        throw new Error("No access token found");
      }

      const response = await axios.get(
        "https://backend.wanij.com/auth/details/",
        {
          headers: {
            Authorization: `Bearer ${tokens.access}`,
          },
        }
      );

      return response.data;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue({
          error: error.response.data.error,
          error_type: error.response.data.error_type,
        });
      }
      return rejectWithValue("Failed to fetch user details");
    }
  }
);

// Async Thunk to check auth status and refresh token if needed
export const checkAuthStatus = createAsyncThunk(
  "auth/checkStatus",
  async (_, { dispatch, getState }) => {
    const tokens = getAuthTokens();
    if (!tokens) return false;

    const { auth } = getState();
    if (isTokenExpired(tokens.access)) {
      try {
        await dispatch(refreshAccessToken()).unwrap();
        // After successful token refresh, fetch user details
        await dispatch(getUserDetails()).unwrap();
        return true;
      } catch (error) {
        dispatch(logout());
        return false;
      }
    } else {
      // If token is valid but we don't have user details, fetch them
      if (!auth.user) {
        try {
          await dispatch(getUserDetails()).unwrap();
        } catch (error) {
          dispatch(logout());
          return false;
        }
      }
      return true;
    }
  }
);

const initialState = {
  user: null,
  tokens: getAuthTokens() || { access: "", refresh: "" },
  isAuthenticated: getAuthTokens() ? true : false,
  loading: false,
  error: null,
  registrationSuccess: false,
  errorType: null,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    logout: (state) => {
      state.user = null;
      state.tokens = { access: "", refresh: "" };
      state.isAuthenticated = false;
      removeAuthTokens();
    },
    clearError: (state) => {
      state.error = null;
      state.errorType = null;
    },
    setUser: (state, action) => {
      state.user = action.payload;
      state.isAuthenticated = true;
    },
    clearRegistrationSuccess: (state) => {
      state.registrationSuccess = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(login.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.errorType = null;
      })
      .addCase(login.fulfilled, (state, action) => {
        state.tokens = action.payload?.tokens;
        state.user = action.payload?.user;
        state.isAuthenticated = true;
        state.loading = false;
      })
      .addCase(login.rejected, (state, action) => {
        state.error = action.payload.error;
        state.errorType = action.payload.error_type;
        state.loading = false;
      })
      .addCase(googleLogin.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.errorType = null;
      })
      .addCase(googleLogin.fulfilled, (state, action) => {
        state.tokens = action.payload?.tokens;
        state.user = action.payload.user;
        state.isAuthenticated = true;
        state.loading = false;
      })
      .addCase(googleLogin.rejected, (state, action) => {
        state.error = action.payload.error;
        state.errorType = action.payload.error_type;
        state.loading = false;
      })
      .addCase(refreshAccessToken.fulfilled, (state, action) => {
        state.tokens = action.payload;
      })
      .addCase(refreshAccessToken.rejected, (state) => {
        state.user = null;
        state.tokens = { access: "", refresh: "" };
        state.isAuthenticated = false;
        removeAuthTokens();
      })
      .addCase(checkAuthStatus.fulfilled, (state, action) => {
        state.isAuthenticated = action.payload;
      })
      .addCase(register.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.errorType = null;
        state.registrationSuccess = false;
      })
      .addCase(register.fulfilled, (state, action) => {
        state.registrationSuccess = true;
        state.tokens = action.payload?.tokens;
        state.user = action.payload.user;
        state.isAuthenticated = true;
        state.loading = false;
      })
      .addCase(register.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload.error;
        state.errorType = action.payload.error_type;
        state.registrationSuccess = false;
      })
      .addCase(getUserDetails.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.errorType = null;
      })
      .addCase(getUserDetails.fulfilled, (state, action) => {
        state.loading = false;
        state.user = action.payload?.user;
        state.isAuthenticated = true;
        state.error = null;
        state.errorType = null;
      })
      .addCase(getUserDetails.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload.error;
        state.errorType = action.payload.error_type;

        // Optionally reset auth state if user details fetch fails
        if (action.payload === "No access token found") {
          state.isAuthenticated = false;
          state.user = null;
          removeAuthTokens();
        }
      });
  },
});

export const { logout, setUser, clearRegistrationSuccess, clearError } =
  authSlice.actions;
export default authSlice.reducer;
