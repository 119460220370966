import e from "cors";
import React, { useState } from "react";
import { Link } from "react-router-dom";

const API_URL = `${process.env.REACT_APP_API_URL}`;
function RequestPassword() {
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [status, setStatus] = useState({ type: "", message: "" });
  const [loading, setLoading] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!email) {
      setError("Email is required");
      return;
    }
    setLoading(true);
    setStatus({ type: "", message: "" });
    try {
      const response = await fetch(`${API_URL}/request-password-reset/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email }),
      });

      if (response.ok) {
        setStatus({
          type: "success",
          message:
            "If an account exists with this email, you will receive a password reset link.",
        });
        setEmail("");
      } else {
        throw new Error("Failed to request password reset");
      }
    } catch (error) {
      setStatus({
        type: "error",
        message: "An error occurred. Please try again later.",
      });
    } finally {
      setLoading(false);
    }
  };

  const validateEmail = (email) => {
    if (!email.trim()) return "Email is required";
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return !emailRegex.test(email) ? "Please enter a valid email address" : "";
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setEmail(value);
    setStatus({ type: "", message: "" });

    if (value) {
      setError(validateEmail(value));
    }
  };
  return (
    <section class="bg-gray-50 D1:bg-gray-900">
      <div class="flex flex-col items-center mt-40 px-6 py-8 mx-auto md:h-screen lg:py-0">
        {/* <a
          href="#"
          class="flex items-center mb-6 text-2xl font-semibold text-gray-900 D1:text-white"
        >
          <img
            class="w-8 h-8 mr-2"
            src="https://flowbite.s3.amazonaws.com/blocks/marketing-ui/logo.svg"
            alt="logo"
          />
          Flowbite
        </a> */}

        <div class="w-full  bg-white rounded-lg shadow D1:border md:mt-0 sm:max-w-md D1:bg-gray-800 D1:border-gray-700 sm:p-8">
          {status.type === "success" ? (
            <div
              class="flex items-center p-4 mb-4 text-sm text-green-800 border border-green-300 rounded-lg bg-green-50 D:bg-gray-800 D:text-green-400 D:border-green-800"
              role="alert"
            >
              <svg
                fill="none"
                height="24"
                viewBox="0 0 24 24"
                width="24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect
                  fill="#d4efee"
                  height="23"
                  rx="11.5"
                  width="23"
                  x=".5"
                  y=".5"
                />
                <path
                  d="m7 12 3 3 7-7"
                  stroke="#408380"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                />
                <rect
                  height="23"
                  rx="11.5"
                  stroke="#005955"
                  width="23"
                  x=".5"
                  y=".5"
                />
              </svg>
              <span class="sr-only">Info</span>
              <div className="ml-2">
                We have emailed you a link to reset your password
              </div>
            </div>
          ) : status.type === "error" ? (
            <div
              class="flex items-center p-4 mb-4 text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 D:bg-gray-800 D:text-red-400 D:border-red-800"
              role="alert"
            >
              <svg
                class="flex-shrink-0 inline w-4 h-4 me-3"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                viewBox="0 0 20 20"
              >
                <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z" />
              </svg>
              <span class="sr-only">Info</span>
              <div>
                <span class="font-medium">Account not found!</span> Please enter
                a valid email
              </div>
            </div>
          ) : null}
          <h2 class="mb-1 text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl D1:text-white">
            Enter your Email
          </h2>
          <form class="mt-4 space-y-4 lg:mt-5 md:space-y-5" action="#">
            <div>
              {/* <label
                for="email"
                class="block mb-2 text-sm font-medium text-gray-900 D1:text-white"
              >
                Your email
              </label> */}
              <input
                className={`w-full px-8 py-4 rounded-lg font-medium bg-gray-100 border ${
                  error ? "border-red-500" : "border-gray-200"
                } placeholder-gray-500 text-sm focus:outline-none focus:border-gray-400 focus:bg-white`}
                type="email"
                onChange={handleChange}
                placeholder="Email"
                required
              />
              <p
                id="standard_error_help"
                class="mt-2 text-xs text-red-600 D1:text-red-400"
              >
                {error}
              </p>
            </div>

            {/* <div className="relative mt-5 mb-2">
              <input
                className="w-full px-8 py-4 rounded-lg font-medium bg-gray-100 border border-gray-200 placeholder-gray-500 text-sm focus:outline-none focus:border-gray-400 focus:bg-white"
                type={showPassword ? "text" : "password"}
                onChange={(e) => setPassword(e.target.value)}
                placeholder="Password"
                value={password}
              />
              <button
                type="button"
                className="absolute inset-y-0 right-3 flex items-center text-gray-500"
                onClick={() => setShowPassword((prev) => !prev)}
              >
                {showPassword ? (
                  <VisibilityOffTwoToneIcon />
                ) : (
                  <VisibilityTwoToneIcon />
                )}
              </button>
            </div> */}

            <div class="flex items-start">
              <div class="flex items-center h-5">
                <input
                  id="newsletter"
                  aria-describedby="newsletter"
                  type="checkbox"
                  required
                  defaultChecked
                  onClick={(e) => setButtonDisabled(!buttonDisabled)}
                />
              </div>
              <div class="ml-3 text-sm">
                <label
                  for="newsletter"
                  class="font-light text-gray-500 D1:text-gray-300"
                  onClick={(e) => setButtonDisabled(!buttonDisabled)}
                >
                  I accept the{" "}
                  <Link
                    class="font-medium text-primary-600 hover:underline"
                    to={"/TandC"}
                  >
                    Terms and Conditions
                  </Link>
                </label>
              </div>
            </div>
            <button
              onClick={handleSubmit}
              className={`mt-5 tracking-wide font-semibold w-full py-4 rounded-lg transition-all duration-300 ease-in-out flex items-center justify-center focus:shadow-outline focus:outline-none
                ${
                  !buttonDisabled
                    ? "bg-blue-700 text-gray-100 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300"
                    : "bg-gray-300 text-gray-500 cursor-not-allowed"
                }`}
              disabled={buttonDisabled}
            >
              {/* <svg
                className="w-6 h-6 -ml-2"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <path d="M16 21v-2a4 4 0 00-4-4H5a4 4 0 00-4 4v2" />
                <circle cx="8.5" cy="7" r="4" />
                <path d="M20 8v6M23 11h-6" />
              </svg> */}
              <span className="ml-3">
                {loading ? "Sending..." : "Request Password Reset"}
              </span>
            </button>
          </form>
        </div>
      </div>
    </section>
  );
}

export default RequestPassword;
